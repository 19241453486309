import { useContext } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { ViewContext } from "../../context/ViewContext";
import { setCard } from "../../features/card/cardSlice";
import { getProduct } from "../../services/shopServices";
import GettingDataSpinner from "../includes/Spinners/GettingDataSpinner";

const Product = () => {
  // * ------------------------------ variables ------------------------------
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const user = useSelector((state) => state.user);
  const card = useSelector((state) => state.card);
  const uuid = require("uuid");
  const cookies = new Cookies();
  const [product, setProduct] = useState({});
  const [wordCount, setWordCount] = useState(1000);
  const [contentCount, setContentCount] = useState(1);

  const [price, setPrice] = useState(0);

  const { slug } = useParams();

  // * ------------------------------ life cycle -----------------------------
  useEffect(() => {
    getProductHandler();
  }, [user]);

  // * ------------------------------ functions ------------------------------

  const getProductHandler = async () => {
    try {
      if (user.token === "") return;
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);
      const token = user.token;
      const { data } = await getProduct(token, slug);
      console.log("data:", data);
      setProduct(data.doc);
      setDialog(false);
    } catch (error) {
      setDialog(false);

      toast.error(error);
    }
  };

  const caculator = (e) => {
    setPrice(wordCount * contentCount * product.price);
  };

  const submitHandler = (e) => {
    caculator(e);
    let products = [];

    // Validation for minimum requirements
    if (wordCount <= 700 || contentCount <= 0) {
        return toast.error(
            "The minimum order quantity is 1, and the word count must be at least 700 characters."
        );
    }

    // Add existing products from the cart
    if (card.products.length > 0) {
        products = [...card.products];
    }

    // Add the new product to the products array
    products.push({
        wordCount: wordCount,
        count: contentCount,
        product_id: product._id,
        price: wordCount * contentCount * product.price,
        img: product.imageCover || "/img/post-img.webp",
        name: product.name,
        slug: product.slug,
        id: uuid.v1(),
    });

    // Save updated cart to cookies
    cookies.set("card", products, {
        path: "/",
        expires: new Date(Date.now() + 2592000), // Expires in 30 days
    });

    // Update Redux state
    store.dispatch(setCard(products));

    // Success notification
    toast.success("Product added to the cart.");
};


  const onChangeHandler = (e, state) => {
    if (e.target.value < 0 || e.target.value == 0) {
      return toast.error("تعداد کلمات و تعداد محتوا نمی تواند منفی باشد");
    }
    state(e.target.value);
  };

  return (
    <div className="h-full overflow-y-scroll p-2 container">
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex gap-2 text-gray-500">
          <Link to={"/"}>Home</Link> /<Link to={"/shop"}>Shop</Link>
        </div>
        <h1 className="text-[2rem] bg-gradient-to-r to-Primary from-dark-200 font-bold text-transparent bg-clip-text">
          {product.name}
        </h1>
      </div>
      <div className="grid grid-cols-12 mb-10 gap-4">
        <div className="col-span-12 lg:col-span-7 xl:col-span-9">
          <img
            src={
              product.imageCover && product.imageCover !== ""
                ? product.imageCover
                : "/img/post-img.webp"
            }
            alt={product.name}
            className="w-full rounded"
          />
          <article>{product.mainText}</article>
        </div>
        <div className="col-span-12 lg:col-span-5 xl:col-span-3">
          <div className="flex bg-white rounded flex-col p-4 gap-4">
            <h2 className="font-bold text-dark-200">
              Order <span className="text-orange-500">Content Production</span>
            </h2>
            <hr className="opacity-50" />
            <div className="flex">
              <p className="w-[150px]">Rate:</p>
              <input
                readOnly
                value={product.price}
                type="text"
                className="focus:outline-none rounded w-full bg-gray-100 p-1 text-center"
              />
            </div>
            <div className="flex">
              <p className="w-[150px]">Status:</p>
              <input
                value={
                  product.status === "orderable" ? "Orderable" : "Not Orderable"
                }
                type="text"
                className="focus:outline-none rounded w-full bg-gray-100 p-1 text-center"
              />
            </div>
            <hr className="opacity-50" />
            <div className="flex">
              <p className="w-[150px]">Word Count:</p>
              <input
                onChange={(e) => {
                  onChangeHandler(e, setWordCount);
                }}
                type="number"
                className="focus:outline-none rounded w-full bg-gray-100 p-1 text-center"
                placeholder="+1000"
              />
            </div>
            <div className="flex">
              <p className="w-[150px]">Content Count:</p>
              <input
                onChange={(e) => {
                  onChangeHandler(e, setContentCount);
                }}
                type="number"
                className="focus:outline-none rounded w-full bg-gray-100 p-1 text-center"
                placeholder="+1"
              />
            </div>
            <hr className="opacity-50" />
            <div className="flex justify-center items-end gap-1">
              <p className="lg:text-3xl text-sm">{price.toLocaleString()}</p>
              <p className="lg:text-3xl text-sm">Dollor</p>
            </div>
            <button
              className="bg-green-400 text-white p-1 w-full rounded"
              onClick={caculator}
            >
              Calculate Price
            </button>
            {product.status === "orderable" ? (
              <>
                <button
                  className="bg-orange-500 text-white p-1 w-full rounded"
                  onClick={submitHandler}
                >
                  Add to Cart
                </button>
              </>
            ) : (
              <button
                className="bg-orange-500 text-white p-1 w-full rounded"
                disabled
              >
                This product is fully booked.
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Product;
