import { useEffect } from "react";

const Pagination = ({ page, results, setPage, limit, setLimit }) => {
  const nextPage = () => {
    if (page * limit < results) setPage(page + 1);
  };

  const previousPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const setLimitation = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };
  return (
    <div className="w-full flex justify-end items-center gap-6 mb-10 p-2 flex-wrap">
      <div className="flex gap-4 items-center mb-1">
        <button
          className="hover:text-Primary transition-all duration-[.3s]"
          onClick={nextPage}
          disabled={page * limit >= results ? true : false}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <button
          className=" hover:text-Primary transition-all duration-[.3s]"
          onClick={previousPage}
          disabled={page <= 1 ? true : false}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
      <div className="flex items-center gap-2">
        <p>{(page - 1) * limit + 1} </p>
        <p>to</p>
        <p> {page * limit <= results ? page * limit : results} </p>
        <p>-</p>
        <p> {results} </p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="m-0">limit:</p>
        <select
          name=""
          id=""
          className=" rounded px-2 border-none outline-none mb-[2px]"
          onChange={setLimitation}
        >
          <option value="10">10</option>

          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;
