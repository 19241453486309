import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

const SERVER_URL = "https://api.modirecontent.com/api/v1/users";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1/users";




export const serverLogin = (email, password) => {
  const url = `${SERVER_URL}/login`;
  return axios.post(url, {
    email,
    password,
  });
};

export const serverRegister = (data) => {
  const url = `${SERVER_URL}/sign-up`;
  return axios.post(url, data);
};

export const serverVerifyPhone = (code, password, passwordConfirm) => {
  const url = `${SERVER_URL}/verify-email`;
  return axios.post(url, {
    code,
    password,
    passwordConfirm,
  });
};

export const serverResendCode = (email) => {
  const url = `${SERVER_URL}/email-verify-code/`;
  return axios.post(url, {
    email,
  });
};

export const serverForgotPassword = (email) => {
  const url = `${SERVER_URL}/forgot-password`;
  return axios.post(url, {
    email,
  });
};

export const serverResetPassword = (code, password, passwordConfirm) => {
  const url = `${SERVER_URL}/reset-password`;
  return axios.post(url, {
    code,
    password,
    passwordConfirm,
  });
};

export const getMe = (token) => {
  const url = `${SERVER_URL}/profile`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.get(url);
};
