import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";

import Spinner from "../../includes/Spinner";

import {
  updateContentProject,
  updateHandler as contentUpdateHandler,
  statusHandler as updateCustomerStatus,
  updateManyV2,
} from "../../../services/contentServices";

const CustomerEditDialog = ({ ids=[], content = {}, projects = [] }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [projectloading, setProjectLoading] = useState(false);
  const [statusloading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  const [status, setStatus] = useState("");

  const [newStatus, setNewStatus] = useState("");

  const [subject, setSubject] = useState("");
  const [project, setProject] = useState({});
  const [projectID, setProjectID] = useState("");

  useEffect(() => {
    setSubject(content.subject || "");
    setKeywords(content.keywords || "");
    setDescription(content.description || "");
  }, []);

  const onChangeHandler = (e, state) => {
    console.log(e.target.value);
    state(e.target.value);
  };
  const trimHandler = (state) => {
    const text = state;
    return text.trim() === "";
  };
  
  const updateHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setLoading(true);
      if (
        trimHandler(keywords) &&
        trimHandler(description) &&
        trimHandler(subject) &&
        trimHandler(status)
      ) {
        setLoading(false);
        return toast.info("You must fill in at least one field to update.");
      }
  
      const { data } = await updateManyV2(token, {
        contentsID: ids,
        updateState: "customer",
        update: {
          keywords,
          subject,
          description,
          status: newStatus,
        },
      });
      toast.success("Content updated successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      // toast.warning(error.message);
    }
  };
  

  const projectHandler = async (e) => {
    e.preventDefault();
    if (projectID === "") return toast.info("Please select a project first");
  
    if (projectID === project.id)
      return toast.info("The content is already in the selected project and does not need an update");
  
    try {
      const token = user.token;
      if (!token) return;
      setProjectLoading(true);
      const { data } = await updateContentProject(token, {
        project: projectID,
        id: content._id,
      });
      setProject({
        id: data.content.project._id,
        name: data.content.project.name,
      });
  
      toast.success("Content project updated successfully");
      setProjectLoading(false);
    } catch (error) {
      setProjectLoading(false);
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  


  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">Edit Content</p>
        <button
          className="text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
  
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Index Number:</p>
        <input
          value={content._id}
          readOnly
          type="text"
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Subject:</p>
        <input
          onChange={(e) => onChangeHandler(e, setSubject)}
          placeholder={content.subject}
          type="text"
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
        />
      </div>
  
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Project:</p>
        <div className="flex w-full">
          <select
            onChange={(e) => {
              onChangeHandler(e, setProjectID);
            }}
            name=""
            id=""
            className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
          >
            <option value="" selected disabled>
              Please select a project.
            </option>
            {projects.map((el) => (
              <option value={`${el._id}`}>{el.name}</option>
            ))}
          </select>
          <button
            disabled={projects.length < 1 || projectloading}
            onClick={projectHandler}
            className="bg-green-400 px-2 rounded-r text-white flex items-center gap-1 w-[120px] justify-center"
          >
            {projectloading ? <Spinner /> : <span>Submit</span>}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Status:</p>
        <div className="flex w-full">
          <select
            onChange={(e) => {
              onChangeHandler(e, setNewStatus);
            }}
            name=""
            id=""
            className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
          >
            <option value="" selected disabled>
              Please select a status.
            </option>
            {content.status.stage === "Pending Customer" &&
            content.status.text === "ordered" ? (
              <option value="Submitted">Submitted for Writing</option>
            ) : (
              <>
                <option value="Content Approved">Employer Approved</option>
                <option value="Employer Not Approved">Employer Not Approved</option>
              </>
            )}
          </select>
        </div>
      </div>
  
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Keywords:</p>
        <input
          onChange={(e) => onChangeHandler(e, setKeywords)}
          placeholder={content.keywords}
          type="text"
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full">
        <p className="w-[150px]">Description:</p>
        <textarea
          onChange={(e) => onChangeHandler(e, setDescription)}
          placeholder={content.description}
          type="text"
          className="p-2 w-full md:h-[170px] overflow-y-scroll text-primary outline-none border-none rounded bg-gray-100 resize-none"
        />
      </div>
      <button
        className="w-full bg-green-400 text-white p-2 flex justify-center items-center gap-4"
        onClick={updateHandler}
        disabled={loading}
      >
        {loading ? <Spinner /> : ""}
        <span>Edit Content Information</span>
      </button>
    </div>
  );
  
};

export default CustomerEditDialog;
