const Profile = () => {
  return (
    <div className="grid grid-cols-12 gap-4 p-8 overflow-y-scroll h-full">
      <div className="col-span-12 lg:col-span-5 flex flex-col gap-4">
        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex justify-between items-start ">
            <div className="flex flex-col ">
              <p className="text-blue-900 font-bold text-[20px]">
                Personal Information
              </p>
              <p className="text-gray-400 text-[14px]">Your Profile</p>
            </div>

            <p className="text-gray-400 text-[14px] text-left mt-[2px] hidden md:block">
              Joined At 2024/05/10
            </p>
          </div>
          <div className="flex gap-4 items-center flex-wrap md:flex-nowrap">
            <div className="relative">
              <img
                src="/img/profile-placeholder.jpg"
                alt=""
                className="w-[100px] rounded-xl"
              />
              <button className="absolute bottom-2 right-1 transition-all duration-[0.3s] cursor-pointer p-[2px] rounded-full bg-white text-blue-900 hover:text-white hover:bg-purple-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col">
              <p className="font-bold text-purple-800 text-[20px]">
                Mohammad Bashardoost
              </p>
              <p className="text-gray-400 text-[14px]">
                Modire Content Company
              </p>
            </div>
          </div>
        </div>

        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex flex-col ">
            <p className="text-blue-900 font-bold text-[20px]">
              Password Setting:
            </p>
            <p className="text-gray-400 text-[14px]">Your Password</p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="email"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Current Password:
              </label>
              <input
                name="email"
                type="text"
                placeholder="Enter your Current Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="email"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                New Password:
              </label>
              <input
                name="email"
                type="text"
                placeholder="Enter your new Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="email"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Confirm Password:
              </label>
              <input
                name="email"
                type="text"
                placeholder="Confirm your Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex gap-2 items-center flex-wrap justify-end">
              <button
                type="submit"
                className="px-10 py-2 w-full md:w-auto bg-purple-100 bg-opacity-50 text-purple-800 font-medium text-center rounded-full hover:bg-transparent border-2 border-transparent hover:border-purple-800 border-purple-700 flex items-center justify-center gap-4 transition-all duration-[0.3s]"
              >
                <span>Update Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-7 ">
        <div className="rounded-2xl bg-white flex flex-col p-6 gap-4">
          <div className="flex flex-col ">
            <p className="text-blue-900 font-bold text-[20px]">Edit Profile:</p>
            <p className="text-gray-400 text-[14px]">
              Edit your personal Information
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="name"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Your FullName:
              </label>
              <input
                name="name"
                type="text"
                placeholder="Mohammad bashardoost"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Your Username:
              </label>
              <input
                name="Username"
                type="text"
                placeholder="Mohammadbashardoost"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Company’s name:
              </label>
              <input
                name="Username"
                type="text"
                placeholder="Mohammadbashardoost"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="email"
                className="w-[max-content] text-purple-800 font-semibold"
              >
                Email:
              </label>
              <input
                name="email"
                type="text"
                placeholder="Confirm your Password"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
          
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Phone Number:
              </label>
              <input
                name="Username"
                type="text"
                placeholder="Mohammadbashardoost"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex flex-col justify-between items-start gap-[4px]">
              <label
                htmlFor="Username"
                className="w-[max-content] text-purple-800  font-semibold"
              >
                Your Address:
              </label>
              <input
                name="Username"
                type="text"
                placeholder="Mohammadbashardoost"
                className="bg-[#F5F2F2] border-none outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
              />
            </div>
            <div className="flex gap-2 items-center flex-wrap justify-end">
              <button
                type="submit"
                className="px-10 py-2 w-full md:w-auto bg-purple-100 bg-opacity-50 text-purple-800 font-medium text-center rounded-full hover:bg-transparent border-2 border-transparent hover:border-purple-800 border-purple-700 flex items-center justify-center gap-4 transition-all duration-[0.3s]"
              >
                <span>Edit Information</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
