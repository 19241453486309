import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";

const Order = ({ order = [] }) => {
  const { setDialog } = useContext(ViewContext);

  return (
    <div className="flex flex-col p-2 w-full h-full gap-8 overflow-y-scroll">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <h2>Order Details</h2>
        <button
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
  
      {/* Order Details Header */}
      <div className="grid grid-cols-3">
        <div className="col-span-1 text-center">Order Quantity:</div>
        <div className="col-span-1 text-center">Word Count:</div>
        <div className="col-span-1 text-center">Product Type:</div>
      </div>
  
      {/* Order Items */}
      {order.product.map((el) => (
        <div className="grid grid-cols-3 gap-2" key={el.id}>
          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden">Order Quantity:</p>
            <p className="p-1 w-full bg-gray-100 rounded">{el.count}</p>
          </div>
  
          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden">Word Count:</p>
            <p className="p-1 w-full bg-gray-100 rounded">{el.wordCount}</p>
          </div>
  
          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden">Product Type:</p>
            <p className="p-1 w-full bg-gray-100 rounded">
              {el.product === "content" ? "Content Creation" : ""}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
  
};

export default Order;
