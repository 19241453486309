import { useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  serverForgotPassword,
  serverResendCode,
  serverResetPassword,
} from "../../services/authServices";
import Spinner from "../includes/Spinner";

const ForgotPassword = () => {
  // ------------------ variables -------------------------------

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [codeSendedAt, setTime] = useState(Date.now());

  // ------------------ functions -------------------------------

  // #handleresetPassword send code,password and handleResetCode to server and  reset the password
  const handleresetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (code === "" || password === "" || passwordConfirm === "") {
      setLoading(false);
      return toast.info("لطفا فیلد ها را به درستی پر کنید.");
    }
    try {
      const { data } = await serverResetPassword(
        code,
        password,
        passwordConfirm
      );
      setLoading(false);
      setRedirect(true);
      return toast.success("تغییر رمز عبور شما انجام شد.");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #handleResendCode send a new code to the number
  const handleResendCode = async (e) => {
    e.preventDefault();

    if (Date.now() - codeSendedAt < 120000)
      return toast.info("You must wait 2 minute before getting a new code");

    try {
      const { data } = await serverForgotPassword(email);
      setView(false);
      setLoading(false);

      return toast.success("The Verification Code had been sended");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };
  // #resetPasswordHandler send email to the server for sending code to the number
  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email === "") {
      setLoading(false);
      return toast.info("Please Enter Your Email");
    }

    try {
      const { data } = await serverForgotPassword(email);
      setView(false);
      setLoading(false);

      return toast.success("The Verification Code had been sended");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #onChangeHandler handel the input changes
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  // ------------------ View -------------------------------

  return (
    <>
      {redirect ? <Navigate to="/auth/login" /> : ""}

      {view ? (
        <div
          action=""
          className="flex flex-col gap-4 h-[237px] "
        >
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label htmlFor="email" className="w-[max-content] text-gray-600">
            Email:
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setEmail)}
              value={email}
              readOnly={loading}
              name="email"
              type="text"
              placeholder="Example: info@modirecontent.com"
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            <button
              type="submit"
              readOnly={loading}
              className="px-16 py-2 w-full md:w-auto bg-purple-800 text-white text-center rounded-full  flex items-center justify-center gap-4"
              onClick={resetPasswordHandler}
            >
              {loading ? <Spinner /> : ""}
              <span>Continue</span>
            </button>
          </div>

        </div>
      ) : (
        <div action="" className="flex flex-col gap-4 ">
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label htmlFor="code" className="w-[max-content] text-gray-600">
              Code:
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setCode)}
              readOnly={loading}
              name="code"
              value={code}
              type="text"
              placeholder="123456"
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px] ">
            <label htmlFor="password" className="w-[max-content] text-gray-600">
              Password:
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setPassword)}
              value={password}
              readOnly={loading}
              name="password"
              type="password"
             
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px] ">
            <label htmlFor="passwordConfirm" className="w-[max-content] text-gray-600">
              Password Confirm:
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setPasswordConfirm)}
              readOnly={loading}
              value={passwordConfirm}
              name="passwordConfirm"
              type="password"
             
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            <button
              type="submit"
              disabled={loading}
              className="px-16 py-2 w-full md:w-auto bg-purple-800 text-white text-center rounded-full  flex items-center justify-center gap-4"
              onClick={handleresetPassword}
            >
              {loading ? <Spinner /> : ""}
              <span>Reset Password</span>
            </button>
            <p className="text-[14px]">
              Didn't get the code?{" "}
              <button
                className="text-purple-800"
                to="/auth/forget-password"
                onClick={handleResendCode}
                disabled={loading}
              >
                Resend
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
