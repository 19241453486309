import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ProjectEditDialog from "./ProjectEditDialog";
// import EditDialogHandler from "../EditDialogs/EditDialogHandler";

const ProjectTable = ({
  projects = [],
  setDialog,
  setDialogClasses,
  openEditDialog,
}) => {

  const editProjectDialog = (el) => {
    setDialog(<ProjectEditDialog project={el} />);
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[600px] flex flex-col md:h-[500px] rounded p-4 items-center justify-center gap-4 shadow-lg"
    );
  };
  const token = useSelector((state) => state.user.token);

  return (
    <div className="flex flex-col p-2 text-[14px] xl:text-md mb-6">
      {/* Header for the table */}
      <div className="grid-cols-12 mb-2 hidden lg:grid items-right gap-2">
        <div className="col-span-12 flex flex-col lg:flex-row lg:items-center gap-2">
          <div className="flex gap-2 lg:w-[40%] pr-2">
            <p className="text-Primary">Title</p>
          </div>
          <hr className="opacity-50 mb-2" />
          <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
            <div className="col-span-9 text-center">Website Address</div>
            <div className="lg:col-span-3 text-center">Actions:</div>
          </div>
        </div>
      </div>
      <hr className="opacity-50 mb-4 hidden lg:block" />
  
      {/* List of projects */}
      <div className="grid grid-cols-12 mb-2 items-right gap-4">
        {projects.map((el) => (
          <div
            key={el._id}
            className="col-span-12 flex flex-col lg:flex-row lg:items-center gap-2 bg-white rounded-lg p-2"
          >
            {/* Project Name */}
            <div className="flex gap-2 lg:w-[40%]">
              <p className="text-Primary">
                <Link to={`/projects/${el._id}`}>{el.name}</Link>
              </p>
            </div>
            <hr className="opacity-50 mb-2" />
  
            {/* Project Details */}
            <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
              <div className="flex items-center sm:col-span-6 lg:col-span-9 lg:text-center">
                <p className="w-[150px] lg:hidden">Website Address:</p>
                <p
                  className="p-1 w-full bg-gray-100 rounded"
                  style={{ direction: "ltr" }}
                >
                  {el.siteName}
                </p>
              </div>
  
              {/* Project Actions */}
              <div className="flex items-center sm:col-span-6 lg:col-span-3">
                <div className="p-1 w-full hidden lg:flex gap-4 justify-center items-center">
                  {/* View Project */}
                  <Link to={`/projects/${el._id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                      />
                    </svg>
                  </Link>
  
                  {/* Download Structure File */}
                  {el.contentStructureFile ? (
                    <Link
                      target={"_blank"}
                      type={"download"}
                      to={`https://api.modirecontent.com/api/v1/projects/download-file/${el._id}?token=${token}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                        />
                      </svg>
                    </Link>
                  ) : (
                    <button
                      onClick={() =>
                        toast.info(
                          "No structure file is defined for this project."
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                        />
                      </svg>
                    </button>
                  )}
  
                  {/* Edit Project */}
                  <button onClick={() => editProjectDialog(el)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <hr className="opacity-50 mb-2 lg:hidden" />
          </div>
        ))}
      </div>
    </div>
  );
  
  
};

export default ProjectTable;
