import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

const AuthLayouts = () => {
  const url = window.location.pathname;
  const user = useSelector((state) => state.user);

  const [title, setTitle] = useState("پنل کاربری مدیر محتوا");
  const [urlAddress, setUrlAddress] = useState("#");
  const [anchorText, setAnchorText] = useState("");
  const cookies = new Cookies();

  useEffect(() => {
    protect();
    if (url === "/auth") {
      window.location.replace("/auth/login");
    }

    if (url === "/auth/login") {
      setTitle("Welcome Back!");
      setUrlAddress("/auth/sign-up");
      setAnchorText("Don't have an account?");

    } else if (url === "/auth/sign-up") {
      setTitle("Welcome to Content Planet");
      setUrlAddress("/auth/login");
      setAnchorText("Already have an account?");
    } else if (url === "/auth/forget-password") {
      setTitle("Reset Your Password");
      setUrlAddress("/auth/login");
      setAnchorText("Already have an account?");
    }
  }, [url]);

  const protect = () => {
    if (user.token !== "") {
      window.location.replace("/");
    } else {
      if (cookies.get("auth")) {
        window.location.replace("/");
      }
    }
  };

  return (
    <div className="container h-[100vh] overflow-auto  flex justify-center items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2  h-[40rem]  lg:max-w-screen-lg w-full p-4 relative">
        <div
          className=" col-span-1 bg-white justify-center px-4 py-2 lg:py-4 h-full flex flex-col gap-8 rounded-xl lg:rounded-r-none shadow-regular z-10 backdrop-blur-[2px] relative"
       
        >
          <div className="flex flex-col">
            <div className="flex flex-col h-full">
              <img src="/img/Logo-Text.png" alt="" className="w-[25%]" />
            </div>
            <h1 className="bg-gradient-to-r to-purple-100  from-purple-800 font-bold text-transparent bg-clip-text text-[26px] mb-0">
              {title}
            </h1>
            {window.location.pathname.startsWith("/auth/login") ? (
              <p className="text-[14px] ">
                Dont have an account?{" "}
                <Link to="/auth/sign-up" className="text-purple-800">
                  Sign Up
                </Link>
              </p>
            ) : (
              <p className="text-[14px] ">
                Already Have an Account?{" "}
                <Link to="/auth/login" className="text-purple-800">
                  Login
                </Link>
              </p>
            )}
          </div>
          <Outlet />
          <div className="flex flex-col items-center">
            <p className="text-primary text-[10px] ">
              powerd by{" "}
              <Link
                to="https://marketingplanet.agency"
                className="text-title font-bold uppercase"
              >
                {" "}
                Marketing Planet Agency{" "}
              </Link>{" "}
              ©
            </p>
          </div>
        </div>
        <div className="col-span-1 absolute w-full opacity-20 h-full flex justify-center lg:justify-normal items-center lg:static lg:opacity-100 rounded-2xl lg:rounded-l-none lg:rounded-r-2xl overflow-hidden">
          <img
            src="/img/test.png"
            alt=""
            className=" h-full rounded-r-2xl hidden lg:block "
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayouts;
