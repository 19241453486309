import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { updateManyV2 as updateMany } from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";
import Spinner from "../../includes/Spinner";

const AccountManagerEditBox = ({ ids, content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  //*information
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  //* designer and Media Manager
  const [mediaManager, setMediaManager] = useState("");
  const [designer, setDesigner] = useState("");

  const [mediaManagers, setMediaManagers] = useState([]);
  const [designers, setDesigners] = useState([]);

  const [mediaManagerInput, setMediaManagerInput] = useState("");
  const [designerInput, setDesignerInput] = useState("");

  //* Content Writer and Editor
  const [contentManager, setContentManager] = useState("");
  const [contentManagers, setContentManagers] = useState([]);
  const [contentManagerSearch, setContentManagerSearch] = useState("");

  const [contentWriter, setContentWriter] = useState("");
  const [contentWriters, setContentWriters] = useState([]);
  const [contentWriterSearch, setContentWriterSearch] = useState("");

  const [contentEditor, setContentEditor] = useState("");
  const [contentEditors, setContentEditors] = useState([]);

  const [contentEditorSearch, setContentEditorSearch] = useState("");

  //* finalwordcount
  const [finalWordCount, setFinalWordCount] = useState("");

  //* Rating System
  const [contentGrammarRate, setContentGrammarRate] = useState("");
  const [contentReadabilityRate, setContentReadabilityRate] = useState("");
  const [contentVisualRate, setContentVisualRate] = useState("");
  const [contentQualityRate, setContentQualityRate] = useState("");
  const [contentSEORate, setContentSEORate] = useState("");

  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;
  const [paymentStatus, setPeymentStatus] = useState("");

  const [dueDate, setDueDate] = useState("");
  const [writerDueDate, setWriterDueDate] = useState("");
  const [editorDueDate, setEditorDueDate] = useState("");
  const [contentLink, setContentLink] = useState("");

  //*  Status
  const [status, setStatus] = useState("");
  const [statusInput, setStatusInput] = useState("");

  const [imageStatus, setImageStatus] = useState("");
  const [imageStatusInput, setImageStatusInput] = useState("");

  useEffect(() => {
    if (content.dueDate) {
      const match = content.dueDate.match(regex);
      setDueDate(match ? match[1] : "2024-08-20T17:30:00");
    }
    if (content.contentDueDate) {
      if (content.contentDueDate.writer) {
        const match = content.contentDueDate.writer.match(regex);
        setWriterDueDate(match ? match[1] : "2024-08-20T17:30:00");
      }
    }

    if (content.contentDueDate) {
      if (content.contentDueDate.editor) {
        const match = content.contentDueDate.editor.match(regex);
        setEditorDueDate(match ? match[1] : "2024-08-20T17:30:00");
      }
    }

    setSubject(content.subject ? content.subject : "");
    setKeywords(content.keywords ? content.keywords : "");
    setDescription(content.description ? content.description : "");

    setStatus(content.status);
    setContentWriter(content.writer.length > 0 ? content.writer[0]._id : "");
    setContentEditor(content.editor.length > 0 ? content.editor[0]._id : "");
    setFinalWordCount(content.finalWordCount ? content.finalWordCount : "");
    setPeymentStatus(content.peymentStatus ? content.peymentStatus : "");

    setContentGrammarRate(
      content.contentGrammarRate ? content.contentGrammarRate : ""
    );
    setContentReadabilityRate(
      content.contentReadabilityRate ? content.contentReadabilityRate : ""
    );
    setContentVisualRate(
      content.contentVisualRate ? content.contentVisualRate : ""
    );
    setContentQualityRate(
      content.contentQualityRate ? content.contentQualityRate : ""
    );
    setContentSEORate(content.contentSeoRate ? content.contentSeoRate : "");

    setImageStatus(content.imageStatus ? content.imageStatus : "");

    setLoading(false);
  }, []);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    const token = user.token;
    if (!token) return;
    try {
      setLoading(true);
      const update = {};

      if (statusInput != "") update.status = statusInput;

      if (statusInput != "") update.status = statusInput;
      if (imageStatusInput != "") update.imageStatus = imageStatusInput;
      if (paymentStatus != "") update.paymentStatus = paymentStatus;

      if (contentWriter != "") update["contentTeam.writer"] = contentWriter;
      if (contentEditor != "") update["contentTeam.editor"] = contentEditor;
      if (contentManager != "")
        update["contentTeam.contentManager"] = contentManager;

      if (mediaManager != "") update["designTeam.designManager"] = mediaManager;
      if (designer != "") update["designTeam.designer"] = designer;
      
      if (keywords) update.keywords = keywords;
      if (description) update.description = description;
      if (subject) update.subject = subject;

      if (dueDate != "") update.dueDate = `${dueDate}Z`;
      if (writerDueDate != "")
        update["contentDueDate.writer"] = `${writerDueDate}Z`;
      if (editorDueDate != "")
        update["contentDueDate.editor"] = `${editorDueDate}Z`;

      if (finalWordCount != "") update.finalWordCount = finalWordCount;

      if (contentGrammarRate != "")
        update.contentGrammarRate = contentGrammarRate;
      if (contentQualityRate != "")
        update.contentQualityRate = contentQualityRate;
      if (contentSEORate != "") update.contentSeoRate = contentSEORate;
      if (contentReadabilityRate != "")
        update.contentReadabilityRate = contentReadabilityRate;
      if (contentVisualRate != "") update.contentVisualRate = contentVisualRate;

      const { data } = await updateMany(token, {
        updateState: "accountManager",
        contentsID: ids,
        update: update,
      });

      setLoading(false);
      toast.success("Content has been updated");
    } catch (error) {
      setDialog(false);

      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const searchDesignTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;

      if (!token) return toast.error("Please log in again.");

      const { data } = await GetUsers(token, {
        name: mediaManagerInput,
        userType: "designTeam",
        content: "",
      });
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setMediaManagers([]);
      toast.error(error.response.data.message);
    }
  };

  const searchContentTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;

      if (!token) return toast.error("Please log in again.");

      const { data } = await GetUsers(token, {
        name: state,
        userType: "contentTeam",
        content: "",
      });
      console.log(data);
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setContentManagers([]);
      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
            {content.subject}
          </p>
        </div>
        <button
          onClick={() => setDialog(false)}
          className="p-1 rounded bg-purple-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-purple-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
      <div className="grid grid-cols-12 w-full gap-4">
        <div className=" col-span-12 lg:col-span-6 flex flex-col gap-4 w-full">
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Subject:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={subject}
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                  onChange={(e) => onChangeHandler(e, setSubject)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Description:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={description}
                  onChange={(e) => onChangeHandler(e, setDescription)}
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Keywords:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={keywords}
                  onChange={(e) => onChangeHandler(e, setKeywords)}
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={dueDate}
                  onChange={(e) => onChangeHandler(e, setDueDate)}
                  type="datetime-local"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Writer's DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={writerDueDate}
                  onChange={(e) => onChangeHandler(e, setWriterDueDate)}
                  type="datetime-local"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-2 ">
            <p className="text-[#8B8D97] ">Editor's DueDate:</p>
            <div className="flex flex-col sm:flex-row w-full justify-between    rounded-lg gap-2">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <input
                  value={editorDueDate}
                  onChange={(e) => onChangeHandler(e, setEditorDueDate)}
                  type="datetime-local"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <hr className=" text-gray-500 opacity-50 mt-2" />
          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
            <div className="flex gap-2 w-full flex-col sm:flex-row justify-between rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Grammar Rate:
                </label>
                <input
                  value={contentGrammarRate}
                  onChange={(e) => onChangeHandler(e, setContentGrammarRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Quality Rate:
                </label>
                <input
                  value={contentQualityRate}
                  onChange={(e) => onChangeHandler(e, setContentQualityRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
            <div className="flex gap-2 flex-col sm:flex-row w-full justify-between    rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Readability Rate:
                </label>
                <input
                  value={contentReadabilityRate}
                  onChange={(e) =>
                    onChangeHandler(e, setContentReadabilityRate)
                  }
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Visuals Rate:
                </label>
                <input
                  value={contentVisualRate}
                  onChange={(e) => onChangeHandler(e, setContentVisualRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Content SEO Rate:
            </label>
            <input
              value={contentSEORate}
              onChange={(e) => onChangeHandler(e, setContentSEORate)}
              type="Number"
              className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
            />
          </div>
        </div>
        <div className=" col-span-12 lg:col-span-6 flex gap-4 flex-col  ">
          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Content Status:</p>
            <div className="flex w-full">
              <select
                onChange={(e) => onChangeHandler(e, setStatusInput)}
                type="text"
                className="bg-[#EFF1F999] px-2 h-[42px] py-2 outline-none w-full rounded-l-lg text-[#8B8D97] "
                placeholder="Search the content title"
              >
               <option value="" selected disabled>
              Select the desired option
            </option>

            <option value="Assigned to Content Manager">
              Assigned to Content Manager
            </option>
            <option value="Account Manager Not Approved">
              Account Manager Not Approved
            </option>

            {/* Content Manager's */}
            <option value="Manager Not Approved">Manager Not Approved</option>
            <option value="Manager Approved">Manager Approved</option>
            <option value="Needs Editor Rewrite">Needs Editor Rewrite</option>
            <option value="Assigned to Writer">Assigned to Writer</option>

            {/* Editor's */}
            <option value="AI Check">Sent for AI Check</option>
            <option value="Need Rewrite">Needs Rewrite</option>
            <option value="Editor Rewriting">Editor Rewriting</option>
            <option value="Editor Rewritten">Editor Rewrite Completed</option>
            <option value="Editor Approved">Editor Approved</option>

            {/* Writer's */}
            <option value="Written">Writing Completed</option>
            <option value="In Writing">In Writing</option>

            {/* Customer's */}
            <option value="Content Approved">Employer Approved</option>
            <option value="Employer Not Approved">Employer Not Approved</option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Image Status:</p>
            <div className="flex w-full">
              <select
                onChange={(e) => onChangeHandler(e, setImageStatusInput)}
                type="text"
                className="bg-[#EFF1F999] h-[42px] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
            <option value="" selected disabled>
              Select the desired option
            </option>

            <option value="Assign to Design Manager">
              Assigned to Design Manager
            </option>
            <option value="Assign to be Designed">
              Sent for Design Process
            </option>
            <option value="Images Assigned to be Designed">
              Sent to Designer
            </option>
            <option value="Manager has not approved Images">
              Manager Not Approved Images
            </option>
            <option value="Images require redesigning">
              Images Need Redesign
            </option>
            <option value="Images designed">Images Designed</option>
            <option value="Images redesigned">Images Redesigned</option>
            <option value="Design Manager Accepted">
              Design Manager Approved
            </option>
            <option value="Manager approved images">
              Manager Approved Images
            </option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
            <p className="text-[#8B8D97] ">Peyment Status:</p>
            <div className="flex w-full">
              <select
                onChange={(e) => onChangeHandler(e, setPeymentStatus)}
                type="text"
                className="bg-[#EFF1F999] px-2 h-[42px] py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
                <option value="" selected>
                  Select
                </option>

                <option value="Payed">Payed</option>
                <option value="Not Payed">Not Payed</option>
                <option value="Full Time">Full Time</option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <hr className=" text-gray-500 opacity-50 mt-2" />
          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Content Manager:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  onChange={(e) => onChangeHandler(e, setContentManagerSearch)}
                  placeholder={
                    content.contentManager && content.contentManager.length > 0
                      ? content.contentManager[0].name ||
                        content.contentManager[0].username
                      : "Not assigned to any Content Manager"
                  }
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                />
                <button
                  className="bg-green-50 text-green-900 rounded-r-lg px-2 "
                  onClick={(e) =>
                    searchContentTeam(
                      e,
                      setContentManagers,
                      contentManagerSearch
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Content Manager:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] h-[40px] px-2 py-2 rounded-l-lg outline-none w-full text-[#8B8D97]"
                  onChange={(e) => {
                    onChangeHandler(e, setContentManager);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {contentManagers.map((el) => (
                    <option value={`${el._id}`}>
                      {el.name ? el.name : el.username}
                    </option>
                  ))}
                </select>

                <button
                  className="border border-purple-800 rounded-r-lg px-2 text-purple-800 p-2 text-center"
                  title="Delete Access"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Writer:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  onChange={(e) => onChangeHandler(e, setContentWriterSearch)}
                  placeholder={
                    content.writer && content.writer.length > 0
                      ? content.writer[0].name || content.writer[0].username
                      : "Not assigned to any Writer"
                  }
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                />
                <button
                  className="bg-green-50 text-green-900 rounded-r-lg px-2 "
                  onClick={(e) =>
                    searchContentTeam(e, setContentWriters, contentWriterSearch)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Writer:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] h-[40px] px-2 py-2 rounded-l-lg outline-none w-full text-[#8B8D97]"
                  onChange={(e) => {
                    onChangeHandler(e, setContentWriter);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {contentWriters.map((el) => (
                    <option value={`${el._id}`}>
                      {el.name ? el.name : el.username}
                    </option>
                  ))}
                </select>

                <button
                  className="border border-purple-800 rounded-r-lg px-2 text-purple-800 p-2 text-center"
                  title="Delete Access"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Editor:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                  onChange={(e) => onChangeHandler(e, setContentEditorSearch)}
                  placeholder={
                    content.editor && content.editor.length > 0
                      ? content.editor[0].name || content.editor[0].username
                      : "Not assigned to any Editor."
                  }
                />
                <button
                  className="bg-green-50 text-green-900 rounded-r-lg px-2 "
                  onClick={(e) => searchContentTeam(e, setContentEditors,contentEditorSearch)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Editor:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] h-[40px] px-2 py-2 outline-none rounded-l-lg text-[#8B8D97] w-full "
                  onChange={(e) => {
                    onChangeHandler(e, setContentEditor);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {contentEditors.map((el) => (
                    <option value={`${el._id}`}>
                      {el.name ? el.name : el.username}
                    </option>
                  ))}
                </select>

                <button
                  className="border border-purple-800 rounded-r-lg px-2 text-purple-800 p-2 text-center"
                  title="Delete Access"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Media Manager:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  onChange={(e) => onChangeHandler(e, setMediaManagerInput)}
                  placeholder={
                    content.designManager && content.designManager.length > 0
                      ? content.designManager[0].name ||
                        content.designManager[0].username
                      : "Not assigned to any Media Manager"
                  }
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                />
                <button
                  className="bg-green-50 text-green-900 rounded-r-lg px-2 "
                  onClick={(e) =>
                    searchDesignTeam(e, setMediaManagers, mediaManagerInput)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Media Manager:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] h-[40px] px-2 py-2 rounded-l-lg outline-none w-full text-[#8B8D97]"
                  onChange={(e) => {
                    onChangeHandler(e, setMediaManager);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {mediaManagers.map((el) => (
                    <option value={`${el._id}`}>
                      {el.name ? el.name : el.username}
                    </option>
                  ))}
                </select>

                <button
                  className="border border-purple-800 rounded-r-lg px-2 text-purple-800 p-2 text-center"
                  title="Delete Access"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Designer:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  onChange={(e) => onChangeHandler(e, setDesignerInput)}
                  placeholder={
                    content.Designer && content.Designer.length > 0
                      ? content.Designer[0].name || content.Designer[0].username
                      : "Not assigned to any Designer"
                  }
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                />
                <button
                  className="bg-green-50 text-green-900 rounded-r-lg px-2 "
                  onClick={(e) =>
                    searchDesignTeam(e, setDesigners, designerInput)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Designer:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] h-[40px] px-2 py-2 rounded-l-lg outline-none w-full text-[#8B8D97]"
                  onChange={(e) => {
                    onChangeHandler(e, setContentWriter);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {designers.map((el) => (
                    <option value={`${el._id}`}>
                      {el.name ? el.name : el.username}
                    </option>
                  ))}
                </select>

                <button
                  className="border border-purple-800 rounded-r-lg px-2 text-purple-800 p-2 text-center"
                  title="Delete Access"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Final Word Count:</p>
              <div className="flex w-full">
                <input
                  onChange={(e) => onChangeHandler(e, setFinalWordCount)}
                  type="number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                  placeholder="Final Word Count"
                  value={finalWordCount}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row col-span-12 gap-2 w-full">
          <button
            className="px-5 py-2 bg-purple-50 text-purple-900 rounded-full"
            onClick={() => {
              setDialog(false);
            }}
          >
            {" "}
            Cancel
          </button>
          <button
            className="px-5 py-2 bg-purple-900 text-white rounded-full"
            onClick={updateHandler}
          >
            {" "}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountManagerEditBox;
