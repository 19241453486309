import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  serverRegister,
  serverResendCode,
  serverVerifyPhone,
} from "../../services/authServices";
import Spinner from "../includes/Spinner";

const SignUp = () => {
  // ------------------ variables -------------------------------

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [username, setUsername] = useState(true);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [codeSendedAt, setTime] = useState(Date.now());

  // ------------------ functions -------------------------------

  // #handleSignUp verifies email sign-up by sending code, password, and email to the server
  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!code || !password || !passwordConfirm || !email) {
      setLoading(false);
      return toast.info("Please fill out all fields correctly.");
    }

    try {
      const { data } = await serverVerifyPhone(code, password, passwordConfirm);
      setLoading(false);
      setRedirect(true);
      return toast.success("Your registration was successful.");
    } catch (error) {
      setLoading(false);
      return toast.error(
        error.response?.data?.message ||
          "An error occurred during registration."
      );
    }
  };

  // #handleResendCode sends a new verification code to the email number
  const handleResendCode = async (e) => {
    e.preventDefault();

    if (Date.now() - codeSendedAt < 120000) {
      return toast.info(
        "Please wait two minutes before requesting another code."
      );
    }

    try {
      const { data } = await serverResendCode(email);
      setTime(Date.now());
      toast.success("Your verification code has been resent.");
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while resending the code."
      );
    }
  };

  // #signupHandler sends the email number and username to the server for sign-up and code generation
  const signupHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email || !username) {
      setLoading(false);
      return toast.info("Please enter your email and username correctly.");
    }

    try {
      const { data } = await serverRegister({ email, username });
      setView(false);
      setLoading(false);
      return toast.success("A verification code has been sent to your email.");
    } catch (error) {
      setLoading(false);
      return toast.error(
        error.response?.data?.message || "An error occurred during sign-up."
      );
    }
  };

  // #onChangeHandler handel the input changes
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  // ------------------ View -------------------------------

  return (
    <>
      {redirect && <Navigate to="/auth/login" />}
      {view ? (
        <div className="flex flex-col gap-4 h-[237px] ">
          <div className="flex flex-col justify-between items-start gap-[4px] ">
            <label htmlFor="username" className="w-[max-content] text-gray-600">
             Username:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setUsername)}
              name="username"
              type="text"
              placeholder="Choose a Username:"
              className="bg-[#fff] border-2 outline-none p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px] ">
            <label htmlFor="email" className="w-[max-content] text-gray-600">
              Email:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setEmail)}
              name="email"
              type="email"
              placeholder="info@modirecontent.com"
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>

          <div className="flex gap-2 items-center flex-wrap">
            <button
              type="submit"
              readOnly={loading}
              className="px-16 py-2 w-full md:w-auto bg-purple-800 text-white text-center rounded-full  flex items-center justify-center gap-4"
              onClick={signupHandler}
            >
              {loading ? <Spinner /> : ""}
              <span>Continue</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label htmlFor="code" className="w-[max-content] text-gray-800">
              Email:
            </label>
            <input
              readOnly={true}
              name="email"
              value={email}
              type="text"
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm text-gray-800 border-purple-500"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label htmlFor="code" className="w-[max-content] text-gray-600">
              Verification Code:
            </label>
            <input
              readOnly={loading}
              name="code"
              value={code}
              type="text"
              onChange={(e) => onChangeHandler(e, setCode)}
              placeholder="Enter the verification code: 123456"
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label htmlFor="password" className="w-[max-content] text-gray-600">
              Password:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setPassword)}
              name="password"
              type="password"
              placeholder="Choose a password."
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-[4px]">
            <label
              htmlFor="passwordConfirm"
              className="w-[max-content] text-gray-600"
            >
              Confirm Password:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setPasswordConfirm)}
              name="passwordConfirm"
              type="password"
              placeholder="Enter your password again."
              className="bg-[#fff] border-2 outline-none  p-2 w-full rounded-full text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex gap-2 items-center flex-wrap">
          <button
            type="submit"
            disabled={loading}
            className="px-16 py-2 w-full md:w-auto bg-purple-800 text-white text-center rounded-full  flex items-center justify-center gap-4"
            onClick={handleSignUp}
          >
            {loading ? <Spinner /> : ""}
            <span>Login</span>
          </button>
          <p className="text-[14px]">
            Didn't get the code?{" "}
            <button className="text-purple-800" to="/auth/forget-password" onClick={handleResendCode} disabled={loading}>
              Resend
            </button>
          </p>
        </div>
       
        </div>
      )}
    </>
  );
};

export default SignUp;
