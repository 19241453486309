import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { updateManyV2 as updateMany } from "../../../services/contentServices";

const EditorEdtitBox = ({ ids, content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  //*  Status
  const [status, setStatus] = useState("");
  const [statusInput, setStatusInput] = useState("");
  const [contentGrammarRate, setContentGrammarRate] = useState("");
  const [contentReadabilityRate, setContentReadabilityRate] = useState("");
  const [contentVisualRate, setContentVisualRate] = useState("");
  const [contentQualityRate, setContentQualityRate] = useState("");
  const [contentSEORate, setContentSEORate] = useState("");

  useEffect(() => {
    setStatus(content.status);

    setContentGrammarRate(
      content.contentGrammarRate ? content.contentGrammarRate : ""
    );
    setContentReadabilityRate(
      content.contentReadabilityRate ? content.contentReadabilityRate : ""
    );
    setContentVisualRate(
      content.contentVisualRate ? content.contentVisualRate : ""
    );
    setContentQualityRate(
      content.contentQualityRate ? content.contentQualityRate : ""
    );
    setContentSEORate(content.contentSeoRate ? content.contentSeoRate : "");

    setLoading(false);
  }, []);

  const submithandler = async () => {
    try {
      if (status === "") return toast.info("Please select a status first");
      const token = user.token;

      if (!token) return;
      setLoading(true);
      let update = {};
      if (statusInput !="") update.status = statusInput;

      if (contentGrammarRate !="") update.contentGrammarRate = contentGrammarRate;
      if (contentReadabilityRate !="") update.contentReadabilityRate = contentReadabilityRate;
      if (contentVisualRate !="") update.statuscontentVisualRate = contentVisualRate;
      if (contentQualityRate !="") update.contentQualityRate = contentQualityRate;
      if (contentSEORate !="") update.contentSeoRate = contentSEORate;




      const { data } = await updateMany(token, {
        updateState: "editor",
        contentsID: ids,
        update: update,
      });
      if (data.contents.n == 0) {
        toast.info("No content was updated");
      } else {
        toast.success(`Content updated: ${data.contents.n} items`);
      }

      setStatus(status);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        error.response ? error.response.data.message : error.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
            {content.subject}
          </p>
        </div>
        <button
          onClick={() => setDialog(false)}
          className="p-1 rounded bg-purple-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-purple-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
      <div className="grid grid-cols-12 w-full gap-4">
        <div className=" col-span-12  flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Content Status:</p>
            <div className="flex w-full">
              <select
                onChange={(e) => onChangeHandler(e, setStatusInput)}
                type="text"
                className="bg-[#EFF1F999] px-2 h-[42px] py-2 outline-none w-full rounded-l-lg text-[#8B8D97] "
                placeholder="Search the content title"
              >
                <option value="" selected disabled>
                  Select
                </option>

                {/* Editor's */}
                <option value="AI Check">Sent for AI Check</option>
                <option value="Need Rewrite">Needs Rewrite</option>
                <option value="Editor Rewriting">Editor Rewriting</option>
                <option value="Editor Rewritten">
                  Editor Rewrite Completed
                </option>
                <option value="Editor Approved">Editor Approved</option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
            <div className="flex gap-2 w-full flex-col sm:flex-row justify-between rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Grammar Rate:
                </label>
                <input
                  value={contentGrammarRate}
                  onChange={(e) => onChangeHandler(e, setContentGrammarRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Quality Rate:
                </label>
                <input
                  value={contentQualityRate}
                  onChange={(e) => onChangeHandler(e, setContentQualityRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-12  xxl:col-span-4 gap-2 ">
            <div className="flex gap-2 flex-col sm:flex-row w-full justify-between    rounded-lg">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Readability Rate:
                </label>
                <input
                  value={contentReadabilityRate}
                  onChange={(e) =>
                    onChangeHandler(e, setContentReadabilityRate)
                  }
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
                  Content Visuals Rate:
                </label>
                <input
                  value={contentVisualRate}
                  onChange={(e) => onChangeHandler(e, setContentVisualRate)}
                  type="Number"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="" className="text-[#5b5b5b]  w-[200px]">
              Content SEO Rate:
            </label>
            <input
              value={contentSEORate}
              onChange={(e) => onChangeHandler(e, setContentSEORate)}
              type="Number"
              className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-lg text-[#8B8D97]"
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row col-span-12 gap-2 w-full">
          <button
            className="px-5 py-2 bg-purple-50 text-purple-900 rounded-full"
            onClick={() => {
              setDialog(false);
            }}
          >
            {" "}
            Cancel
          </button>
          <button
            className="px-5 py-2 bg-purple-900 text-white rounded-full"
            onClick={submithandler}
          >
            {" "}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditorEdtitBox;
