import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../context/ViewContext";
import { getProducts } from "../../services/shopServices";
import Pagination from "../includes/Pagination";
import GettingDataSpinner from "../includes/Spinners/GettingDataSpinner";

const Shop = () => {
  const user = useSelector((state) => state.user);
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [page, setPage] = useState(1);
  const [results, setResults] = useState(0);
  const [limit, setLimit] = useState(10);

  const [products, setProducts] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [category, setCategory] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    fetchProductHandler();
  }, [refresh, page, user]);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const fetchProductHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);

      const filter = `limit=${limit}${name !== "" ? `&name=${name}` : ""}${
        category !== "" ? `&category=${category}` : ""
      }${page != 1 && page > 1 ? `&page=${page}` : `&page=1`}`;

      if (user.token === "") return;
      const { data } = await getProducts(token, filter);
      console.log(data);
      setProducts(data.products);
      setDialog(false);
    } catch (error) {
      setDialog(false);
      if (error.response.status == 401) {
        return toast.error("Please login again");
      }
    }
  };

  return (
    <div className="h-full overflow-y-scroll">
      <div className="grid grid-cols-12 p-4">
        {/* Main Content Section */}
        <div className="col-span-12 lg:col-span-8">
          <div className="flex flex-col gap-2 mb-4">
            <div className="flex gap-2 text-gray-500">
              <Link to={"#"}>Home</Link> /<Link to={"#"}>Shop</Link>
            </div>
            <h1 className="text-[2rem]">
              <span className="text-orange-500">Our</span> Shop
            </h1>
          </div>
  
          <div className="grid grid-cols-4 lg:grid-cols-3 gap-4 mb-8">
            {products.map((product) => (
              <div
                key={product.id}
                className="col-span-4 md:col-span-2 lg:col-span-1 bg-white shadow-regular p-2 rounded flex flex-col gap-4"
              >
                <img
                  src={
                    product.imageCover && product.imageCover !== ""
                      ? product.imageCover
                      : "/img/post-img.webp"
                  }
                  alt={product.name}
                  className="w-full rounded"
                />
                <p className="text-lg text-title">{product.name}</p>
                <Link
                  to={`/store/${product.slug}`}
                  className="bg-green-400 p-1 rounded text-sm text-white"
                >
                  View & Order Product
                </Link>
              </div>
            ))}
          </div>
  
          <Pagination
            page={page}
            setPage={setPage}
            results={results}
            setResults={setResults}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
  
        {/* Sidebar Section */}
        <div className="col-span-12 lg:col-span-4 flex flex-col gap-4">
          {/* Search Box */}
          <div className="p-2 bg-white rounded">
            <div className="bg-background rounded overflow-hidden flex w-full">
              <input
                type="text"
                placeholder="Search Product"
                className="w-full border-none outline-none p-1 bg-background"
                value={name}
                onChange={(e) => {
                  onChangeHandler(e, setName);
                }}
              />
              <button
                className="p-1 hover:text-Primary transition-all duration-[0.3s]"
                onClick={() => setRefresh(!refresh)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </div>
          </div>
  
          {/* Sort Options */}
          <div className="p-2 flex items-center gap-2 bg-white rounded">
            <p className="w-[250px] text-sm">Sort By:</p>
            <select name="sort" id="sort" className="bg-background py-1 px-2 w-full">
              <option value="content" disabled>
                Content
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Shop;
