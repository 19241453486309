import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { updateManyV2 } from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";

const AccountManagerEditDialog = ({ ids, content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  // content users states
  const [contentManager, setContentManager] = useState("");
  const [writer, setWriter] = useState("");
  const [editor, setEditor] = useState("");

  const [designManager, setDesignManager] = useState("");
  const [designer, setDesigner] = useState("");
  // users list states
  const [contentManagers, setContentManagers] = useState([]);
  const [writers, setWriters] = useState([]);
  const [editors, setEditors] = useState([]);

  const [designManagers, setDesignManagers] = useState([]);
  const [designers, setDesigners] = useState([]);

  // search query state
  const [CMQuery, setCMQuery] = useState("");
  const [WQuery, setWQuery] = useState("");
  const [EQuery, setEQuery] = useState("");
  
  const [DMQuery, setDMQuery] = useState("");
  const [DQuery, setDQuery] = useState("");

  // content informations

  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [contentLink, setContentLink] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [finalWordCount, setFinalWordCount] = useState(0);

  // content statuses
  const [status, setStatus] = useState("");

  const [imageStatus, setImageStatus] = useState("");

  const [imageNewStatus, setImageNewStatus] = useState("");

  const [newStatus, setNewStatus] = useState("");
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  useEffect(() => {
    console.log(content);
    setSubject(content.subject || "");
    setKeywords(content.keywords || "");
    setDescription(content.description || "");
    setContentLink(content.contentLink || "");
    setFinalWordCount(content.finalWordCount);
    setWriter(content.writer.length>0 ? content.writer[0]._id : "");
    setContentManager(
      content.contentManager.length>0 ? content.contentManager[0]._id : ""
    );
    setEditor(content.editor.length>0 ? content.editor[0]._id : "");
    setDesignManager(
      content.designManager.length>0 ? content.designManager[0]._id : ""
    );
    setDesigner(content.designer.length>0 ? content.designer[0]._id : "");

    if (content.dueDate) {
      const match = content.dueDate.match(regex);
      setDueDate(match ? match[1] : "");
    }
  }, []);

  const searchContentTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;

      if (!token) return toast.error("Please log in again.");

      const { data } = await GetUsers(token, {
        name: state,
        userType: "contentTeam",
        content: "",
      });
      console.log(data);
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setContentManagers([]);
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const searchDesignTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;

      if (!token) return toast.error("Please log in again.");

      const { data } = await GetUsers(token, {
        name: DMQuery,
        userType: "designTeam",
        content: "",
      });
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setDesignManagers([]);
      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
    console.log(e.target.value);
  };
  const updateContent = async (e) => {
    e.preventDefault();
    try {
      const token = user.token;
      if (!token) return toast.error("Please log in again.");

      setLoading(true);
      console.log({
        updateState: "accountManager",
        contentsID: ids,
        update: {
          status: newStatus,
          imageStatus: imageNewStatus,
          contentTeam: {
            writer: writer,
            editor: editor,
            contentManager: contentManager,
          },
          designTeam: {
            designer,
            designManager,
          },
          keywords,
          description,
          subject,
          contentLink,
          dueDate: dueDate === "" ? "" : `${dueDate}Z`,
          finalWordCount,
        },
      });
      const { data } = await updateManyV2(token, {
        updateState: "accountManager",
        contentsID: ids,
        update: {
          status: newStatus,
          imageStatus: imageNewStatus,
          contentTeam: {
            writer: writer,
            editor: editor,
            contentManager: contentManager,
          },
          designTeam: {
            designer,
            designManager,
          },
          keywords,
          description,
          subject,
          contentLink,
          dueDate: dueDate === "" ? "" : `${dueDate}Z`,
          finalWordCount,
        },
      });
      toast.success("Content has been successfully updated.");

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="grid grid-cols-12 h-full w-full gap-4 ">
      <div className="col-span-12 flex items-center w-full justify-between ">
        <h2 className=" text-Primary">Edit Content</h2>
        <button onClick={() => setDialog(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-red-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm text-start">Subject:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            value={subject}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm text-start">
          Search Content Manager:
        </p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setCMQuery)}
            placeholder={
              content.contentManager && content.contentManager.length > 0
                ? content.contentManager[0].name ||
                  content.contentManager[0].username
                : "Not assigned to any Content Manager"
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-r-md text-white p-1"
            onClick={(e) => searchContentTeam(e, setContentManagers, CMQuery)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm text-start">
          Select Content Manager:
        </p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setContentManager);
            }}
          >
            <option value="" disabled selected>
              Select the desired option
            </option>
            {contentManagers.map((el) => (
              <option value={`${el._id}`}>
                {el.name ? el.name : el.username}
              </option>
            ))}
          </select>
        </div>{" "}
      </div>
      {/* Editor */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Search Editor</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setEQuery)}
            placeholder={
              content.editor && content.editor.length > 0
                ? content.editor[0].name || content.editor[0].username
                : "Not assigned to any Editor."
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-r-md text-white p-1"
            onClick={(e) => searchContentTeam(e, setEditors, EQuery)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Select Editor:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setEditor);
            }}
          >
            <option value="" disabled selected>
              Select the desired option.
            </option>
            {editors.map((el) => (
              <option value={`${el._id}`}>
                {el.name ? el.name : el.username}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* writer */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Search Writer:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setWQuery)}
            placeholder={
              content.writer && content.writer.length > 0
                ? content.writer[0].name || content.writer[0].username
                : "Not assigned to any Writer"
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-r-md text-white p-1"
            onClick={(e) => searchContentTeam(e, setWriters, WQuery)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Select Writer:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setWriter);
            }}
          >
            <option value="" disabled selected>
              Select the desired option
            </option>
            {writers.map((el) => (
              <option value={`${el._id}`}>
                {el.name ? el.name : el.username}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* media Manager */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Search Media Manager.</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setDMQuery)}
            placeholder={
              content.designManager && content.designManager.length > 0
                ? content.designManager[0].name ||
                  content.designManager[0].username
                : "Not assigned to any Media Manager."
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-r-md text-white p-1"
            onClick={(e) => searchDesignTeam(e, setDesignManagers, DMQuery)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Select Media Manager:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setDesignManager);
            }}
          >
            <option value="" disabled selected>
              Select the desired option
            </option>
            {designManagers.map((el) => (
              <option value={`${el._id}`}>
                {el.name ? el.name : el.username}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* mediaTeam */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Search Designer</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setDQuery)}
            placeholder={
              content.designer && content.designer.length > 0
                ? content.designer[0].name || content.designer[0].username
                : "Not assigned to any Designer."
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-r-md text-white p-1"
            onClick={(e) => searchDesignTeam(e, setDesigners, DQuery)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Select Designer:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setDesigner);
            }}
          >
            <option value="" disabled selected>
              Select the desired option.
            </option>
            {designers.map((el) => (
              <option value={`${el._id}`}>
                {el.name ? el.name : el.username}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* DuaDate & Final Word Count */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Deadline:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setDueDate)}
            value={dueDate}
            type="datetime-local"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Final Word Count</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setFinalWordCount)}
            value={finalWordCount}
            type="number"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
        </div>
      </div>

      {/* Content & Imnage Status */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Content Status</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setNewStatus);
            }}
          >
            <option value="" selected disabled>
              Select the desired option
            </option>

            <option value="Assigned to Content Manager">
              Assigned to Content Manager
            </option>
            <option value="Account Manager Not Approved">
              Account Manager Not Approved
            </option>

            {/* Content Manager's */}
            <option value="Manager Not Approved">Manager Not Approved</option>
            <option value="Manager Approved">Manager Approved</option>
            <option value="Needs Editor Rewrite">Needs Editor Rewrite</option>
            <option value="Assigned to Writer">Assigned to Writer</option>

            {/* Editor's */}
            <option value="AI Check">Sent for AI Check</option>
            <option value="Need Rewrite">Needs Rewrite</option>
            <option value="Editor Rewriting">Editor Rewriting</option>
            <option value="Editor Rewritten">Editor Rewrite Completed</option>
            <option value="Editor Approved">Editor Approved</option>

            {/* Writer's */}
            <option value="Written">Writing Completed</option>
            <option value="In Writing">In Writing</option>

            {/* Customer's */}
            <option value="Content Approved">Employer Approved</option>
            <option value="Employer Not Approved">Employer Not Approved</option>
          </select>
        </div>
      </div>

      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Image Status:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
            onChange={(e) => {
              onChangeHandler(e, setImageNewStatus);
            }}
          >
            <option value="" selected disabled>
              Select the desired option
            </option>

            <option value="Assign to Design Manager">
              Assigned to Design Manager
            </option>
            <option value="Assign to be Designed">
              Sent for Design Process
            </option>
            <option value="Images Assigned to be Designed">
              Sent to Designer
            </option>
            <option value="Manager has not approved Images">
              Manager Not Approved Images
            </option>
            <option value="Images require redesigning">
              Images Need Redesign
            </option>
            <option value="Images designed">Images Designed</option>
            <option value="Images redesigned">Images Redesigned</option>
            <option value="Design Manager Accepted">
              Design Manager Approved
            </option>
            <option value="Manager approved images">
              Manager Approved Images
            </option>
          </select>
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Keywords:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setKeywords)}
            value={keywords}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
        </div>
      </div>

      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">Description :</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <textarea
            onChange={(e) => onChangeHandler(e, setDescription)}
            value={description}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100 overflow-y-scroll resize-none"
          />
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">Content's Link:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setContentLink)}
            value={contentLink}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-gray-100"
          />
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center pb-10 ">
        <button
          className="w-full bg-green-400 text-white p-2 flex justify-center items-center gap-4 "
          onClick={updateContent}
        >
          <p className="text-lg"> Edit Project</p>
        </button>
      </div>
    </div>
  );
};

export default AccountManagerEditDialog;
