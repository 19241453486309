class ContentStatusConvertor {
  contentStatusConverter(status = { stage: "", text: "" }) {
    switch (status.stage) {
      case "Inprogress":
        return "Inprogress";
        break;
      case "Pending Customer":
        if (status.text === "ordered") {
          return "Awaiting submission for writing by the customer";
        }
        if (status.text === "The content is ready for customer review") {
          return "Ready for Approval";
        }
        break;
      case "Content Approved":
        return "Customer approved";

      case "Pending Account Manager":
        return "Awaiting account manager";

      case "Pending Writer":
        if (
          status.text ===
          "Content Manager Assigned Content to Writer for Writing"
        ) {
          return "Awaiting writer";
        }

        if (status.text === "Writer is Currently Writing") {
          return "Writing in progress";
        }

        if (status.text === "Editor Send Content for Rewrite") {
          return "Needs rewriting";
        }

        break;

      case "Pending Editor":
        if (status.text === "Content has been written and is pending editor review") {
          return "Needs editor review";
        }
        if (status.text === "Reviewing the content with AI assistance.") {
          return "Editor is reviewing";
        }
        if (status.text === "The manager does not approve") {
          return "Content manager disapproved";
        }
        if (status.text === "Content requires editor's rewrite") {
          return "Content requires rewriting by the editor";
        }
        if (status.text === "Customer sent content for rewriting") {
          return "Customer disapproved";
        }
        if (status.text === "Editor is currently rewriting") {
          return "Editor is rewriting";
        }
        break;

      case "Pending Content Manager":
        if (
          status.text === "Account Manager Assigned Content to Content Manager"
        ) {
          return "Assigned to content manager";
        }
        if (
          status.text ===
          "Content has been accepted by the editor and needs Content Manager’s review"
        ) {
          return "Needs content manager review";
        }
        if (
          status.text ===
          "Content has been rewritten and is pending Content Manager review"
        ) {
          return "Content rewritten by the editor.";
        }
        if (status.text === "Account Manager sent content for rewriting") {
          return "Account manager disapproved";
        }
      default:
        return "Status not defined";
    }
  }

  mediaStatusConverter(status = { stage: "", text: "" }) {
    switch (status.stage) {
      case "Pending Content Manager":
        if (
          status.text === "The images have been approved by the design manager"
        )
          return "Needs content manager review";
        break;

      case "Pending Design Manager":
        if (status.text === "Assign to be Designed")
          return "Assigned to media manager";

        if (status.text === "The manager has not approved the images")
          return "Content manager disapproved";

        if (
          status.text === "The images have been designed" ||
          status.text === "The images have been redesigned"
        )
          return "Needs design manager review";
        break;

      case "Pending Designer":
        if (status.text === "Images are currently being designed")
          return "Images are being designed";

        if (status.text === "Images Assigned to be Designed")
          return "Images have been assigned to the graphic designer";

        if (status.text === "The Images Require Redesigning")
          return "Images require redesigning";
        break;

      default:
        return "Inprogress";
    }
  }

  SEOStatusConverter(status = { stage: "", text: "" }){
    return "Not Modified yet"
  }
}
module.exports = ContentStatusConvertor;
