import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ViewContext } from "../../../context/ViewContext";
import { updateManyV2 as updateMany  } from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";
import Spinner from "../../includes/Spinner";

const MediaManagerEditDialog = ({ ids = [], content = {} }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [graphists, setGraphists] = useState([]);
  const [graphist, setGraphist] = useState("");
  const [graphistSearchInput, setGraphistSearchInput] = useState("");

  useEffect(() => {
    setGraphist(content.designTeam.designer ? content.designTeam.designer : "");
  }, []);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  const submithandler = async () => {
    try {
      if (status === "") return toast.info("Please select a status first");

      const token = user.token;
      if (!token) return;

      setLoading(true);
      const { data } = await updateMany(token, {
        updateState: "designManager",
        contentsID: ids,
        update: {
          imageStatus: status,
          designTeam: {
            designer: graphist,
          },
        },
      });
      if (data.contents.n == 0) {
        toast.info("No content was updated");

      } else {
        toast.success(`Content updated: ${data.contents.n} items`);
      }

      setStatus(status);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const searchUser = async (e) => {
    e.preventDefault();

    try {
      const token = user.token;
      if (!token) return;
      setSearchLoading(true);
      const { data } = await GetUsers(token, {
        name: graphistSearchInput,
        content: ids[0]._id,
        userRole: "designTeam",
      });
      console.log(data);
      setGraphists(data.user);
      setSearchLoading(false);

      //
    } catch (error) {
      setSearchLoading(false);
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">Edit Content</p>
        <button
          className="text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
  
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">Index Number:</p>
        <input
          value={content._id}
          readOnly
          type="text"
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">Subject:</p>
        <input
          readOnly
          placeholder={content.subject}
          type="text"
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
        />
      </div>
  
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">Status:</p>
        <select
          name=""
          id=""
          className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
          onChange={(e) => onChangeHandler(e, setStatus)}
        >
          <option value="" selected disabled>
            Please select a status.
          </option>
          <option value="Design Manager Approved">Manager Approved</option>
          <option value="Images Assigned to be Designed">
            Sent to Graphic Designer
          </option>
          <option value="Images Require Redesigning">Needs Redesign</option>
          <option value="Images Designed">Images Designed</option>
          <option value="Images is Designing">Images in Progress</option>
        </select>
      </div>
  
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">Search Designer:</p>
        <div className="flex w-full">
          <input
            onChange={(e) => onChangeHandler(e, setGraphistSearchInput)}
            placeholder={content.keywords}
            type="text"
            className="p-2 w-full text-primary outline-none border-none rounded bg-gray-100"
          />
          <button
            className="bg-green-400 rounded-l-md w-[40px] flex justify-center items-center py-1 text-white"
            onClick={searchUser}
          >
            {searchLoading ? (
              <Spinner />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">Select Designer:</p>
        <select
          onChange={(e) => onChangeHandler(e, setGraphist)}
          readOnly
          placeholder={content.description}
          type="text"
          className="p-2 w-full overflow-y-scroll text-primary outline-none border-none rounded bg-gray-100 resize-none"
        >
          <option value="" selected disabled>
            Please select a designer.
          </option>
          {graphists.map((el) => (
            <option value={el._id}>{el.name || el.username}</option>
          ))}
        </select>
      </div>
      <button
        className="w-full bg-green-400 text-white p-2 flex justify-center items-center gap-4"
        onClick={submithandler}
        disabled={loading}
      >
        {loading ? <Spinner /> : ""}
        <p className="text-lg">Edit Content</p>
      </button>
    </div>
  );
  
};
export default MediaManagerEditDialog;
