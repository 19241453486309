import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../context/ViewContext";

import {
  createProject,
  editProject,
  getMyProjects,
  uploadStructureFile,
} from "../../services/projectServices";

import Pagination from "../includes/Pagination";
import GettingDataSpinner from "../includes/Spinners/GettingDataSpinner";
import LoadingSpinner from "../includes/Spinners/LoadingSpinner";
import CreateProject from "./Includes/ProjectCreateDialog";
import ProjectTable from "./Includes/ProjectTable";

const Projects = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [results, setResults] = useState(0);
  const [id, setID] = useState("");
  const [projects, setProjects] = useState([]);
  const [searchName, setSName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const user = useSelector((state) => state.user);

  //create project
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState({});

  let searchstr = `page=${page}&limit=${limit}`;

  useEffect(() => {
    getProjects();
  }, [limit, page, user,refresh]);

  const getProjects = async () => {
    try {
      const token = user.token;
      if (!token) return;

      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);

      const { data } = await getMyProjects(token, searchstr);
      setResults(data.results);

      setProjects(data.projects);

      setDialog(false);
    } catch (error) {
      setDialog(false);

      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    console.log(e.target.value);
    state(e.target.value);
  };

  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&name=${searchName}`;
    if (siteName != "")
      searchstr = searchstr + `&siteName=${siteName.toLowerCase()}`;

    getProjects();
  };

  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };

  //create project
  const createProjectHandler = async (e) => {
    e.preventDefault();

    try {
      setDialog(<LoadingSpinner />);
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[600px] flex flex-col md:h-[500px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );

      const formdata = new FormData();

      formdata.append("name", name);
      formdata.append("siteName", website);
      formdata.append("description", description);
      const token = user.token;
      if (!token) return;

      const { data, status } = await createProject(token, formdata);

      if (file.name) {
        const fileData = new FormData();
        fileData.append("project", data.doc._id);
        fileData.append("file", file);

        await uploadStructureFile(token, fileData);
      }
      toast.success("پروژه شما ساخته شد");
      setName("");
      setDescription("");
      setWebsite("");
      setFile({});
      setDialog(false);
    } catch (error) {
      setDialog(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const createProjectDialog = () => {
    setDialog(<CreateProject />);
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[600px] flex flex-col md:h-[500px] rounded p-4 items-center justify-center gap-4 shadow-lg"
    );
  };

  return (
    <div className="h-full overflow-y-scroll">
      <div className="p-2 mb-8 text-sm">
        <div className="p-2 w-full flex gap-4 flex-wrap rounded bg-white justify-between items-center">
          <div className="col-span-7 sm:col-span-3 lg:col-span-3 flex items-center gap-2">
            <p>Project Name:</p>
            <input
              type="text"
              className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-gray-100"
              placeholder="Please enter your project name."
              onChange={(e) => onChangeHandler(e, setSName)}
            />
          </div>
          <div className="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
            <p>Website Address:</p>
            <input
              type="text"
              className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-gray-100"
              placeholder="Please enter the website address."
              onChange={(e) => onChangeHandler(e, setSiteName)}
            />
          </div>
  
          <div className="flex flex-wrap justify-between gap-2">
            <button
              className="py-1 px-4 bg-green-400 text-white rounded"
              onClick={() => {
                setRefresh(!refresh);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
            <button
              className="py-1 px-4 bg-green-400 text-white rounded"
              onClick={SearchHandler}
            >
              Search
            </button>
            <button
              className="py-1 px-4 bg-Primary text-white rounded"
              onClick={createProjectDialog}
            >
              Create New Project
            </button>
          </div>
        </div>
      </div>
      <ProjectTable
        setDialog={setDialog}
        setDialogClasses={setDialogClasses}
        projects={projects}
      />
      <Pagination
        results={results}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
      />
    </div>
  );
  
};

export default Projects;
