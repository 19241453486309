import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import ContentStatusConvertor from "../../../utils/ContentStatusConvertor";
import {
  getContent,
  getMessages,
  sendMessage,
} from "../../../services/contentServices";
import GettingDataSpinner from "../../includes/Spinners/GettingDataSpinner";
import AccountManagerEditDialog from "./AccountManagerEditDialog";

import ReactMarkdown from "react-markdown";

const AccountManagerContent = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [content, setContent] = useState({});
  const contentStatusConverter = new ContentStatusConvertor()
    .contentStatusConverter;
  const user = useSelector((state) => state.user);
  const { id } = useParams() || "NullPostId";

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getManagerContent();
    getMessageHandler();
  }, [user]);

  const EditDialogHandler = () => {
    setDialog(
      <AccountManagerEditDialog
        ids={[{ _id: content._id }]}
        content={content}
      />
    );
    setDialogClasses(
      "bg-white w-[80%] sm:w-[80%] h-[90%] sm:h-[90%] md:h-[90%] lg:h-[90%] md:w-[70%] flex flex-col  rounded p-4 items-center justify-center gap-4 shadow-lg overflow-y-scroll"
    );
  };

  const mediaStatusConverter = (status = { stage: "", text: "" }) => {
    switch (status.stage) {
      case "Pending Content Manager":
        if (
          status.text === "The images have been approved by the design manager"
        )
          return "Needs content manager review";
        break;
  
      case "Pending Design Manager":
        if (status.text === "Assing to Design Manager")
          return "Assigned to media manager";
  
        if (status.text === "Assing to be Designed")
          return "Sent for design process";
  
        if (status.text === "The manager has not approved the images")
          return "Content manager disapproved";
  
        if (
          status.text === "The images have been designed" ||
          status.text === "The images have been redesigned"
        )
          return "Needs design manager review";
        break;
  
      case "Pending Designer":
        if (status.text === "Images are currently being designed")
          return "Images are being designed";
  
        if (status.text === "Images Assigned to be Designed")
          return "Images have been assigned to the graphic designer";
  
        if (status.text === "The Images Require Redesigning")
          return "Images require redesigning";
        break;
  
      default:
        return "Status not defined";
    }
  };
  
  const getMessageHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      const { data } = await getMessages(token, { contentID: id });
      setMessages(data.messages);
      console.log(data.messages);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    try {
      if (message.length < 10) {
        return toast.info("پیام شما حداقل باید 10 کارکتر داشته باشد");
      }
      const token = user.token;
      if (!token) return;
      const { data } = await sendMessage(token, { contentID: id, message });
      toast.success("پیام شما ارسال شد");
      setMessage("");
      getMessageHandler();
    } catch (error) {
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const DateConvertor = (date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();

    return `${hours}:${minutes}`;
  };

  const RoleConvertor = (role) => {
    switch (role) {
      case "customer":
        return "Employer";
        break;
      case "accountManager":
        return "Account Manager";
        break;
      case "contentManager":
        return "Content Manager";
        break;
      case "writer":
        return "Writer";
        break;
      case "editor":
        return "Editor";
        break;

      case "designer":
        return "Designer";
        break;
      case "designManager":
        return "Media Manager";
        break;
        case "Artificial Intelligence":
        return "AI Assistant"
        break;
      case "system":
        return "System";
        break;
    }
  };

  const messageViewHandler = (message) => {
    if (message.role === "accountManager") {
      return (
        <div className="flex w-full justify-start">
          <div className="flex gap-2 items-end">
            <img
              src="/img/profile-placeholder.jpg"
              alt=""
              className="rounded-full h-10 w-10 p-1 "
            />
            <div className="flex flex-col bg-indigo-600 p-2  w-full ml-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
              <div className="flex gap-2 text-sm text-gray-300 font-light w-full justify-end">
                <p> ارسال شده در</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </div>
              <hr className="opacity-10" />

              <ReactMarkdown>{message.message}</ReactMarkdown>

              <hr className="opacity-10" />
              <div className="flex w-full justify-start">
                <p className="text-sm text-gray-300 font-light">
                  {RoleConvertor(message.role)}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (message.role === "system") {
      return (
        <div className="flex w-full justify-center">
          <div className="flex flex-col bg-indigo-600 p-2  w-full ml-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
            <div className="flex gap-2 text-sm text-gray-300 font-light w-full justify-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                />
              </svg>
              {RoleConvertor(message.role)}
            </div>
            <hr className="opacity-10" />
            <p className="text-center text-[14px] text-[#fff]">
            <ReactMarkdown>{message.message}</ReactMarkdown>
            </p>
            <hr className="opacity-10" />
            <div className="flex w-full justify-center">
              <p className="flex gap-2 text-sm text-gray-300 font-light w-full justify-center ">
                <p> ارسال شده در</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex w-full justify-end">
          <div className="flex gap-2 items-end ">
            <div className="flex flex-col bg-indigo-600 p-2 w-full mr-4 md:w-[400px] lg:w-[500px] gap-2 rounded-md before:">
              <div className="flex gap-2 text-sm text-gray-300 font-light w-full justify-start">
                <p> ارسال شده در</p>
                <p>{DateConvertor(message.createdAt)}</p>
              </div>
              <hr className="opacity-10" />
              <p className="text-[14px] text-[#fff]"> <ReactMarkdown>{message.message}</ReactMarkdown></p>
              <hr className="opacity-10" />
              <div className="flex w-full justify-end">
                <p className="text-sm text-gray-300 font-light">
                  {RoleConvertor(message.role)}
                </p>
              </div>
            </div>
            <img
              src="/img/profile-placeholder.jpg"
              alt=""
              className="rounded-full h-10 w-10 p-1 "
            />
          </div>
        </div>
      );
    }
  };

  const getManagerContent = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);
      const { data } = await getContent(token, id);
      setContent(data.content);

      setDialog(false);
    } catch (error) {
      setDialog(false);
      toast.error(error.response? error.response.data.message: error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="overflow-y-scroll h-full">
      <div className="grid grid-cols-12 p-4 gap-4 items-center ">
        <div className="col-span-12  lg:col-span-4 xl:col-span-3 flex flex-col gap-8 items-center order-2 lg:order-1 mb-16 lg:mb-0">
          <div className="flex w-full flex-col gap-2">
            <p className="w-[150px]">Subject:</p>
            <p
              type="text"
              className="p-1 w-full bg-white rounded  focus:outline-none  "
            >
              {content.subject ? content.subject : "موضوع تعیین نشده"}
            </p>
          </div>

          <div className="flex w-full flex-col gap-2">
            <p className="w-[150px]">Website URL:</p>
            <input
              value={content.project ? content.project.siteName : "فاقد پروژه"}
              type="text"
              className="p-1 w-full bg-white rounded  focus:outline-none  "
            />
          </div>

          <div className="flex w-full flex-col gap-2">
            <p className="w-[150px]">Content Status:</p>
            <input
              value={contentStatusConverter(content.status)}
              type="text"
              className="p-1 w-full bg-white rounded  focus:outline-none  "
            />
          </div>

          <div className="flex w-full flex-col gap-2">
            <p className="w-[150px]">Image Status:</p>
            <p
              type="text"
              className="p-1 w-full bg-white rounded  focus:outline-none  "
            >
              {mediaStatusConverter(content.imageStatus)}
            </p>
          </div>
          <div className="grid grid-cols-3 w-full gap-2">
            <button
              className="col-span-3 xs:col-span-1  px-2 py-1 bg-Primary text-white rounded-md text-sm"
              onClick={EditDialogHandler}
            >
              Edit
            </button>
            <Link target={"_blank"} to={`https://drive.google.com/drive/u/0/folders/${content.contentDrive}`} className="col-span-3 xs:col-span-1 px-2 py-1 bg-orange-500 text-white rounded-md text-sm ">
              View
            </Link>
            <button className="col-span-3 xs:col-span-1 px-2 py-1 bg-green-400 text-white rounded-md text-sm">
              Structure File
            </button>
          </div>
        </div>

        <div className="p-2 col-span-12  lg:col-span-8 xl:col-span-9 h-md:h-[40rem] h-[30rem] order-1 lg:order-2 rounded-2xl  bg-[#dbdde8] flex flex-col gap-2">
          <div className="h-full flex flex-col gap-4 overflow-hidden overflow-y-scroll pb-10">
            {messages.map((el) => messageViewHandler(el))}
          </div>

          <div className="h-[50px]">
            <form className="flex p-1 rounded-full border border-gray-50 ">
              <input
                onChange={(e) => onChangeHandler(e, setMessage)}
                class=" text-black text-xs font-medium leading-4 focus:outline-none w-full bg-transparent p-1"
                placeholder="Type here..."
                value={message}
              />
              <button
                className="rounded-full bg-indigo-600 flex gap-2 text-white py-1 px-4 items-center"
                type="submit"
                onClick={sendMessageHandler}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Send 01">
                    <path
                      id="icon"
                      d="M9.04071 6.959L6.54227 9.45744M6.89902 10.0724L7.03391 10.3054C8.31034 12.5102 8.94855 13.6125 9.80584 13.5252C10.6631 13.4379 11.0659 12.2295 11.8715 9.81261L13.0272 6.34566C13.7631 4.13794 14.1311 3.03408 13.5484 2.45139C12.9657 1.8687 11.8618 2.23666 9.65409 2.97257L6.18714 4.12822C3.77029 4.93383 2.56187 5.33664 2.47454 6.19392C2.38721 7.0512 3.48957 7.68941 5.69431 8.96584L5.92731 9.10074C6.23326 9.27786 6.38623 9.36643 6.50978 9.48998C6.63333 9.61352 6.72189 9.7665 6.89902 10.0724Z"
                      stroke="white"
                      stroke-width="1.6"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
                <p>Send</p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountManagerContent;
