import { Progress } from "@material-tailwind/react";

const AccountManagerDashboard = () => {
    return (
        <div className="h-full overflow-y-scroll">
          <div className="xxl:container grid grid-cols-7 gap-4 p-4  ">
            <div className="col-span-7 lg:col-span-4 xxl:col-span-5 bg-white rounded-2xl flex flex-col p-3 gap-4">
              <div className="flex flex-col">
                <p className="font-semibold text-xl ">Contents Summery</p>
                <p className="text-[14px] text-[#737791]">Your Contents</p>
              </div>
              <div className="grid grid-cols-4 gap-8">
                <div className="col-span-4 sm:col-span-2 lg:col-span-1  rounded-2xl flex sm:flex-col items-center sm:items-start p-4 bg-pink-100 gap-6 sm:gap-2">
                  <figure className="p-1 rounded-full bg-pink-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5 text-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </figure>
    
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-[#425166] text-[14px]">In Progress</p>
                </div>
                <div className="col-span-4 sm:col-span-2 lg:col-span-1 rounded-2xl flex sm:flex-col items-center sm:items-start p-4 bg-orange-200 gap-6 sm:gap-2">
                  <figure className="p-1 rounded-full bg-orange-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5 text-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
                        clipRule="evenodd"
                      />
                      <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
                    </svg>
                  </figure>
    
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-[#425166] text-[14px]">Ready for Approval</p>
                </div>
                <div className="col-span-4 sm:col-span-2 lg:col-span-1 rounded-2xl flex sm:flex-col items-center sm:items-start p-4 bg-green-100 gap-6 sm:gap-2">
                  <figure className="p-1 rounded-full bg-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5 text-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </figure>
    
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-[#425166] text-[14px]">Approved</p>
                </div>
                <div className="col-span-4 sm:col-span-2 lg:col-span-1 rounded-2xl flex sm:flex-col items-center sm:items-start p-4 bg-purple-200 gap-6 sm:gap-2">
                  <figure className="p-1 rounded-full bg-purple-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5 text-white"
                    >
                      <path d="M11.25 5.337c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.036 1.007-1.875 2.25-1.875S15 2.34 15 3.375c0 .369-.128.713-.349 1.003-.215.283-.401.604-.401.959 0 .332.278.598.61.578 1.91-.114 3.79-.342 5.632-.676a.75.75 0 0 1 .878.645 49.17 49.17 0 0 1 .376 5.452.657.657 0 0 1-.66.664c-.354 0-.675-.186-.958-.401a1.647 1.647 0 0 0-1.003-.349c-1.035 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401.31 0 .557.262.534.571a48.774 48.774 0 0 1-.595 4.845.75.75 0 0 1-.61.61c-1.82.317-3.673.533-5.555.642a.58.58 0 0 1-.611-.581c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.035-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959a.641.641 0 0 1-.658.643 49.118 49.118 0 0 1-4.708-.36.75.75 0 0 1-.645-.878c.293-1.614.504-3.257.629-4.924A.53.53 0 0 0 5.337 15c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.036 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.369 0 .713.128 1.003.349.283.215.604.401.959.401a.656.656 0 0 0 .659-.663 47.703 47.703 0 0 0-.31-4.82.75.75 0 0 1 .83-.832c1.343.155 2.703.254 4.077.294a.64.64 0 0 0 .657-.642Z" />
                    </svg>
                  </figure>
    
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-[#425166] text-[14px]">Waiting for Submit</p>
                </div>
              </div>
            </div>
            <div className="col-span-7 lg:col-span-3 xxl:col-span-2 bg-white rounded-2xl flex flex-col p-3 gap-4">
              <p className="font-semibold text-xl">Add More Content</p>
              <div className="xl:flex-row lg:flex-col flex-col xs:flex-row flex gap-4">
                <figure className="p-4 rounded-xl bg-purple-200 flex items-center justify-center">
                  <img
                    src="/img/ordering-content.svg"
                    alt=""
                    className="w-[100px] xl:w-[250px] xxl:w-[150px]"
                  />
                </figure>
                <div className="flex flex-col gap-4">
                  <p className="font-semibold text-[16px]">
                    Ordering Content with Variable Quantities
                  </p>
                  <p className="text-[#737791] text-[14px]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
                  </p>
                  <button className="w-full px-2 py-1 bg-purple-200 text-purple-900 rounded-xl font-semibold">
                    View and Order the Product
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-7 lg:col-span-4 bg-white rounded-2xl p-3 flex flex-col gap-4 ">
              <p className="font-semibold text-xl ">Contents Summery</p>
              <div className="overflow-x-scroll w-full p-2">
                <div className="w-[max-content] flex gap-4 mb-4">
                  <p className="text-[#96A5B8] text-[13px]">#</p>
                  <p className="w-[250px] xxl:w-[300px] text-[#96A5B8] text-[13px]">
                    name
                  </p>
                  <p className="w-[200px]  xxl:w-[300px] text-[#96A5B8] text-[13px]">
                    Content Progress
                  </p>
                  <p className="w-[50px] text-[#96A5B8] text-center text-[13px]">
                    Finished
                  </p>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[250px] xxl:w-[300px]">Lorem ipsum dolor sit ame</p>
    
                  <Progress
                    value={50}
                    color="blue"
                    className="w-[200px] xxl:w-[300px]"
                    size="sm"
                  />
    
                  <p className="w-[50px] p-1 rounded bg-blue-100 text-blue-600 text-center">
                    50%
                  </p>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[250px]  xxl:w-[300px]">Lorem ipsum dolor sit ame</p>
    
                  <Progress
                    value={50}
                    color="blue"
                    className="w-[200px] xxl:w-[300px]"
                    size="sm"
                  />
    
                  <p className="w-[50px] p-1 rounded bg-blue-100 text-blue-600 text-center">
                    50%
                  </p>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[250px]  xxl:w-[300px]">Lorem ipsum dolor sit ame</p>
    
                  <Progress
                    value={50}
                    color="blue"
                    className="w-[200px] xxl:w-[300px]"
                    size="sm"
                  />
    
                  <p className="w-[50px] p-1 rounded bg-blue-100 text-blue-600 text-center">
                    50%
                  </p>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[250px] xxl:w-[300px]">Lorem ipsum dolor sit ame</p>
    
                  <Progress
                    value={50}
                    color="blue"
                    className="w-[200px] xxl:w-[300px]"
                    size="sm"
                  />
    
                  <p className="w-[50px] p-1 rounded bg-blue-100 text-blue-600 text-center">
                    50%
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-7 lg:col-span-3  bg-white rounded-2xl p-3 gap-4 flex flex-col ">
              <p className="font-semibold text-xl ">Ready for Approvals</p>
              <div className="overflow-x-scroll w-full p-2">
                <div className="w-[max-content] flex gap-4 mb-4">
                  <p className="text-[#96A5B8] text-[13px]">#</p>
                  <p className="w-[400px] text-[#96A5B8] text-[13px]">name</p>
    
                  <p className="w-[50px] text-[#96A5B8] text-center text-[13px]">
                    Review
                  </p>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[400px]">Lorem ipsum dolor sit ame</p>
    
                  <div className="flex w-[50px] items-center justify-center bg-blue-50 rounded p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6 text-blue-600 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                  </div>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[400px]">Lorem ipsum dolor sit ame</p>
    
                  <div className="flex w-[50px] items-center justify-center bg-blue-50 rounded p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6 text-blue-600 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                  </div>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[400px]">Lorem ipsum dolor sit ame</p>
    
                  <div className="flex w-[50px] items-center justify-center bg-blue-50 rounded p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6 text-blue-600 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                  </div>
                </div>
                <div className="w-[max-content] flex items-center gap-4 mb-4">
                  <p>1</p>
                  <p className="w-[400px]">Lorem ipsum dolor sit ame</p>
    
                  <div className="flex w-[50px] items-center justify-center bg-blue-50 rounded p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6 text-blue-600 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}
 
export default AccountManagerDashboard;