import { useEffect, useState } from "react";
import { useContext } from "react";
import { ViewContext } from "../../../context/ViewContext";

const AdvanceSearch = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="bg-gradient-to-r to-title text-transparent bg-clip-text to-purple-900 from-purple-700 font-semibold text-2xl">
            Advance Search
          </p>
        </div>
        <button
          onClick={() => setDialog(false)}
          className="p-1 rounded bg-purple-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 text-purple-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <hr className="w-full opacity-50 text-[#8B8D97] mb-6" />
      <div className="grid grid-cols-12 w-full gap-4">
        <div className=" col-span-12 lg:col-span-6 flex gap-8 flex-col  ">
          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Content title:</p>
            <div className="flex w-full">
              <input
                type="text"
                className="bg-[#EFF1F999]  px-4 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              />
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-col  gap-2">
            <p className="text-[#8B8D97] ">Projects:</p>
            <div className="flex w-full">
              <select
                type="text"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
                <option selected value="New">
                  Enhance Dental Centre
                </option>
                <option selected value="Update">
                  Reborn Auto Body
                </option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Content Status:</p>
            <div className="flex w-full">
              <select
                type="text"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
                <option selected value="New">
                  In Progress
                </option>
                <option selected value="Update">
                  Writing
                </option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-[#8B8D97] ">Image Status:</p>
            <div className="flex w-full">
              <select
                type="text"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
                <option selected value="New">
                  In Progress
                </option>
                <option selected value="Update">
                  Writing
                </option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className=" col-span-12 lg:col-span-6 flex flex-col gap-4 w-full">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Content type:</p>
              <div className="flex w-full">
                <select
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                  placeholder="Search the content title"
                >
                  <option selected value="blog">
                    Blog's Content
                  </option>
                  <option selected value="Landing">
                    Landing's Content
                  </option>
                </select>
                <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Content type:</p>
              <div className="flex w-full">
                <select
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                  placeholder="Search the content title"
                >
                  <option selected value="New">
                    New Content
                  </option>
                  <option selected value="Update">
                    Update Content
                  </option>
                </select>
                <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
            <p className="text-[#8B8D97] ">Peyment Status:</p>
            <div className="flex w-full">
              <select
                type="text"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                placeholder="Search the content title"
              >
                <option selected value="New">
                  Payed
                </option>
                <option selected value="Update">
                  Not Payed
                </option>
              </select>
              <button className="bg-red-100 text-red-900 rounded-r-lg px-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 ">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Writer:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                  placeholder="Enter Writer's name"
                />
                <button className="bg-green-50 text-green-900 rounded-r-lg px-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Writer:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] px-2 py-2 rounded-l-lg w-full text-[#8B8D97]"
                >
                  <option value=""> someone</option>
                </select>

                <button
                  className="border border-purple-800 rounded-lg px-2 text-purple-800 p-2 text-center ml-2"
                  title="Contents that doensn't have a writer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 mb-4">
            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Editor:</p>
              <div className="flex w-full">
                <input
                  type="text"
                  className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
                  placeholder="Enter editor's name"
                />
                <button className="bg-green-50 text-green-900 rounded-r-lg px-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-col col-span-2  md:col-span-1 gap-2">
              <p className="text-[#8B8D97] ">Select a Editor:</p>
              <div className="flex w-full">
                <select
                  name=""
                  id=""
                  className="bg-[#EFF1F999] px-2 py-2 rounded-l-lg text-[#8B8D97] w-full "
                >
                  <option value=""> someone</option>
                </select>

                <button
                  className="border border-purple-800 rounded-lg px-2 text-purple-800 p-2 text-center ml-2"
                  title="Contents that doensn't have a writer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-4 ">
          <p className="text-[#8B8D97] ">DueDate:</p>
          <div className="flex  flex-wrap w-full justify-between  bg-[#EFF1F999] px-2 py-1 rounded-lg gap-4">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                From:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                To:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-12 md:col-span-6 xxl:col-span-4 gap-4">
          <p className="text-[#8B8D97] ">Writer's DueDate:</p>
          <div className="flex flex-wrap gap-4 w-full justify-between bg-[#EFF1F999] px-2 py-1 rounded-lg">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                From:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                To:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-12  xxl:col-span-4 gap-4 mb-4">
          <p className="text-[#8B8D97] ">Editor's DueDate:</p>
          <div className="flex flex-wrap gap-4 w-full justify-between bg-[#EFF1F999] px-2 py-1 rounded-lg">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                From:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
              <label htmlFor="" className="text-[#5b5b5b]">
                To:
              </label>
              <input
                type="date"
                className="bg-[#EFF1F999] px-2 py-2 outline-none w-full rounded-l-lg text-[#8B8D97]"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row col-span-12 gap-4 w-full">
          <button className="px-10 py-5 bg-purple-50 text-purple-900 rounded-full">
            {" "}
            Cancel
          </button>
          <button className="px-10 py-5 bg-purple-900 text-white rounded-full">
            {" "}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdvanceSearch;
