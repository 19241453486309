// initial
import axios from "axios";
const SERVER_URL = "https://api.modirecontent.com/api/v1";
const SERVER_URLV2 = "https://api.modirecontent.com/api/v2";

// const SERVER_URL = "http://127.0.0.1:5000/api/v1";
// const SERVER_URLV2 = "http://127.0.0.1:5000/api/v2";

export const getinitialData = (token) => {
  const url = `${SERVER_URL}/content`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(url);
};

export const getContentProjects = (token, data, role) => {
  const url = `${SERVER_URL}/content/projects?${data}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.post(url, { userRole: role });
};

export const getCustomerContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URLV2}/content/customer?${data}`;
  return axios.get(url);
};

export const getContent = (token, id) => {
  let url = `${SERVER_URL}/content/${id}`;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.get(url);
};

export const getCustomerWithoutProject = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/customer/without-project?page=${data}`;
  return axios.get(url);
};

export const updateHandler = (token, data, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/${id}`;
  return axios.post(url, data);
};

export const updateMany = (token, data, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/update-many`;
  return axios.post(url, data);
};
export const updateManyV2 = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URLV2}/content/update`;
  return axios.post(url, data);
};

export const getAccountManagerContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/account-manager?${data}`;
  return axios.get(url, data);
};

export const getContents = (token, query, filter) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/advance-filter?${query}`;
  return axios.post(url, filter);
};

export const getEditorContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/content-editor?${data}`;
  return axios.get(url);
};

export const EditorContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URLV2}/content/editor`;
  return axios.post(url,data);
};

export const getDesignManagerContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/design-manager?${data}`;
  return axios.get(url);
};
export const getDesignerContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/designer?${data}`;
  return axios.get(url);
};
//*--OLD---------------------------------------------------------------------------------------------

export const updateCustomerContent = (token, data, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/customer/${id}`;
  return axios.patch(url, data);
};
export const updateContentProject = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/content/customer/update-project/`;
  return axios.post(url, data);
};
export const statusHandler = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/update-status/`;
  return axios.post(url, data);
};
// -----------------------------------------------------------------------------------

// * Content Manager

export const getManagerContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/content-Manager?${data}`;
  return axios.get(url);
};
export const updateManagerContents = (token, formdata) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/content-manager/update-content`;
  return axios.post(url, formdata);
};
// -----------------------------------------------------------------------------------

//*Content Writer
export const getContentWriterContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URLV2}/content/writer?`;
  return axios.post(url,data);
};



export const getContentExprtContents = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/content-writer?${data}`;
  return axios.get(url);
};

export const contentExpertUpdateStatus = (token, formdata) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/content/content-writer/update-status/`;
  return axios.post(url, formdata);
};
// -----------------------------------------------------------------------------------
//* seel team

export const sellTeamContents = (query, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/content/sell-team?${query}`;
  return axios.get(url);
};
export const sellTeamGetContent = (id, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/content/sell-team/${id}`;
  return axios.get(url);
};
export const sellTeamUpdateContent = (formdata, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/content/sell-team/update-content`;
  return axios.post(url, formdata);
};
// -----------------------------------------------------------------------------------

// * All
export const getMessages = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/message/messages?sort={createdAt=1}`;
  return axios.post(url, data);
};

export const sendMessage = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const url = `${SERVER_URL}/message/send-messages`;
  return axios.post(url, data);
};
