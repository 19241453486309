import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { ViewContext } from "../../context/ViewContext";
import { setUser } from "../../features/user/userSlice";
import sidebar from "./../../data/sidebar.json";
import Spinner from "./Spinner";

const SideBar = () => {
  const cookies = new Cookies();

  const { toggle } = useContext(ViewContext);
  const user = useSelector((state) => state.user);

  const exitHandler = (e) => {
    e.preventDefault();

    cookies.set(
      "auth",
      { key: cookies.get("auth") },
      { path: "/", expires: new Date(Date.now() - 2592000) }
    );
    store.dispatch(
      setUser({
        name: "",
        token: "",
        photo: "",
        id: "",
        role: "",
      })
    );

    window.location.replace("/?logout=true");
  };

  return (
    <div
      className={`${
        !toggle ? "w-[100px]  " : " md:w-[288px] xl:w-[250px]"
      }   py-6 bg-white  hidden h-full transition-all ease-in-out duration-[.3s] md:flex flex-col gap-8 rounded-lg`}
    >
      <div className="mb-4 mx-6 flex gap-2 items-center min-h-16 ">
        <img
          src="/img/Logo.png"
          alt="modirecontent logo"
          className=" w-[70px] "
        />
        <div>
        <img
          src="/img/text-logo.png"
          alt="modirecontent logo"
          className={`${toggle ?"w-[70px]":"w-[0px]"}`}
        />
        </div>
      </div>

      <ul className="flex flex-col gap-4 mx-6 ">
        <li className={`w-full`}>
          <Link
            to="/"
            className={` rounded-[16px] min-h-[40px]  pl-[13px] ${
              window.location.pathname === "/"
                ? "bg-purple-800 text-white"
                : "bg-background text-textGray"
            } flex gap-2 items-center font-bold`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 font-bold "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            <p
              className={`transition-all ease-in-out duration-[.3s]  ${
                !toggle ? "text-[0px]" : "text-base !font-medium"
              }`}
            >
              Dashboard
            </p>
          </Link>
        </li>

        {sidebar.map((el) => (
          <>
            {el.access === user.role ? (
              <li className={`w-full`}>
                <Link
                  to={el.link}
                  className={`rounded-[16px] min-h-[40px] pl-[13px] ${
                    window.location.pathname.startsWith(el.link)
                      ? "bg-purple-800 text-white"
                      : "bg-background text-textGray"
                  } flex gap-2 items-center  font-bold`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor "
                    className="w-6 h-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d={el.icon}
                    />
                  </svg>
                  <p
                    className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                      !toggle ? "text-[0px]" : "text-base"
                    }`}
                  >
                    {el.text}
                  </p>
                </Link>
              </li>
            ) : (
              ""
            )}
          </>
        ))}

        <li className="w-full">
          <Link
            to="/profile"
            className={` rounded-[16px] min-h-[40px]  pl-[13px]  ${
              window.location.pathname.startsWith("/profile")
                ? "bg-purple-800 text-white"
                : "bg-background text-textGray"
            } flex gap-2 items-center  font-bold`}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p
              className={` transition-all ease-in-out duration-[.3s]  ${
                !toggle ? "text-[0px]" : "text-base"
              }`}
            >
              Profile
            </p>
          </Link>{" "}
        </li>
        <li className="w-full">
          {" "}
          <button
            onClick={exitHandler}
            className={`py-1 w-full min-h-[40px] rounded text-textGray  pl-[10px] bg-background flex gap-2 items-center  font-bold`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>

            <p
              className={`pt-1 transition-all ease-in-out duration-[.3s] text-textGray ${
                !toggle ? "text-[0px]" : "text-base"
              }`}
            >
              Exit
            </p>
          </button>{" "}
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
